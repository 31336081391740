import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { PasswordInputStrengthMeter } from '@/containers/pages/user/components/sections/general-info/password/password-input';
import { Button, PasswordInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { generatePassword } from '@/containers/pages/user/components/sections/general-info/password/utils';
import { useDisclosure } from '@mantine/hooks';
import { IPasswordSecurity } from '@/entities/admin-app/filials';
import { useGeneratePolicy } from '@hooks/password/useGeneratePolicy';

interface FormValues {
  Password: string;
  ConfirmPassword: string;
}

interface IFormProps {
  passwordRegexp: RegExp;
  passwordStrengthOptions?: IPasswordSecurity;
  handleUpdatePassword: (value: any) => void;
}
export const ResetPasswordForm = ({
  passwordRegexp,
  handleUpdatePassword,
  passwordStrengthOptions
}: IFormProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [visible, { toggle }] = useDisclosure(false);
  const { copy } = useCopyToClipboard();
  const generatePasswordPolicyString = useGeneratePolicy();

  const schema = z
    .object({
      Password: z.string().refine((value) => passwordRegexp.test(value), {
        message: generatePasswordPolicyString(passwordStrengthOptions)
      }),
      ConfirmPassword: z.string()
    })
    .refine((data) => !data?.ConfirmPassword?.length || data?.Password === data?.ConfirmPassword, {
      message: t('passwordReset.passwordsDoNotMatch'),
      path: ['ConfirmPassword']
    });

  const form = useForm<FormValues>({
    initialValues: { Password: '', ConfirmPassword: '' },
    validateInputOnChange: true,
    validate: zodResolver(schema)
  });

  const { Password, ConfirmPassword } = form.values;

  const onChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const type = event.currentTarget.dataset.value ?? '';
      const value = event.currentTarget.value.replace(/\s+/g, '');
      switch (type) {
        case 'password':
          form.setFieldValue('Password', value);
          break;
        case 'confirm-password':
          form.setFieldValue('ConfirmPassword', value);
          break;

        default:
          break;
      }
      form.validate();
    },
    [Password, ConfirmPassword, passwordRegexp]
  );

  const handleSubmit = async () => {
    const values = form.values;
    handleUpdatePassword(values);
  };

  const handleGeneratePassword = () => {
    const pass = generatePassword(passwordRegexp, passwordStrengthOptions?.MinLength);
    if (pass && passwordRegexp.test(pass)) {
      form.setFieldValue('Password', pass);
      form.setFieldValue('ConfirmPassword', pass);
    }
    copy(pass);
  };

  useEffect(() => {
    if (Password.length) {
      if (passwordRegexp.test(Password)) {
        form.clearFieldError('Password');
      }
    }
  }, [Password, passwordRegexp]);
  useEffect(() => {
    const validatePasswordMatch = () => {
      if (Password.length && ConfirmPassword.length) {
        if (passwordRegexp.test(Password) && Password === ConfirmPassword) {
          form.clearFieldError('ConfirmPassword');
        } else {
          form.setFieldError('ConfirmPassword', t('passwordReset.passwordsDoNotMatch'));
        }
      } else {
        form.clearFieldError('ConfirmPassword');
      }
    };

    validatePasswordMatch();
  }, [Password, ConfirmPassword]);

  useEffect(() => {
    if (passwordRegexp && Password) {
      form.validateField('Password');
    }
  }, [passwordRegexp, Password]);

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <PasswordInputStrengthMeter
        size="lg"
        radius="md"
        variant="filled"
        placeholder={t('passwordReset.newPassword')}
        label=""
        value={form.getInputProps('Password').value}
        onChange={onChange}
        visible={visible}
        onVisibilityChange={toggle}
        handleGeneratePassword={handleGeneratePassword}
        error={form.getInputProps('Password').error}
      />
      <PasswordInput
        size="lg"
        variant="filled"
        placeholder={t('passwordReset.confirmNewPassword')}
        radius="md"
        data-value="confirm-password"
        label=""
        withAsterisk={true}
        visible={visible}
        onVisibilityChange={toggle}
        onChange={onChange}
        value={form.getInputProps('ConfirmPassword').value}
        error={form.getInputProps('ConfirmPassword').error}
        className={classes.input}
      />

      <Button
        size="lg"
        disabled={!form.isValid() || Password !== ConfirmPassword}
        type="submit"
        fullWidth
        mt="xl"
      >
        {t('save')}
      </Button>
    </form>
  );
};
