import React, { FC } from 'react';
import { Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface ITextListFieldProps {
  title: string;
  items?: string[];
  rounded?: boolean;
}

export const TextListField: FC<ITextListFieldProps> = ({ title, items, rounded }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Text className={classes.title}>{title}</Text>
      <Box
        className={cx(classes.listGroup, {
          [classes.group]: rounded
        })}
      >
        {items?.length ? (
          items?.map((item, index) => {
            return (
              <Text
                key={index}
                data-text={item}
                className={cx(classes.description, {
                  [classes.rounded]: item && rounded
                })}
              >
                {item}
              </Text>
            );
          })
        ) : (
          <Text>{t('noInformation')}</Text>
        )}
      </Box>
    </Box>
  );
};
