import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { IRowData } from '@components/simple-table/types';
import { universalNotification } from '@/lib/utils/notification';
import { useChangeStatusesMutation } from '@/entities/admin-app/user/api';
import { usersApi, useLazyGetUsersQuery } from '@/entities/admin-app/users/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { UserStatusEnum } from '@/types/enums/user';
import { useActions } from '../redux/action';
import { useAppSelector } from '../redux/redux';

export const useChangeStatuses = (
  setRowData?: Dispatch<SetStateAction<IRowData[]>>,
  editUser?: boolean
) => {
  const { setUsers } = useActions();
  const [num, setNum] = useState(0);
  const { filters } = useAppSelector((state) => state.users);
  const [fetchChangeStatus, resultChangeStatuses] = useChangeStatusesMutation();
  const [fetchGetUsers] = useLazyGetUsersQuery();

  const changeStatuses = useCallback(async (selection: number[], userStatus: UserStatusEnum) => {
    const numbersSelection = selection?.map((item) => Number(item)) ?? [];
    setNum(numbersSelection.length);
    try {
      const res = await fetchChangeStatus({
        Ids: numbersSelection,
        status: userStatus
      });
      if (!Object.keys(res).includes('error')) {
        clearApiCacheByTags(usersApi, ['Users']);
        const { data } = await fetchGetUsers({
          Count: filters?.Count,
          Offset: 0,
          SortOrder: filters?.SortOrder,
          SortField: filters?.SortField,
          Filters: filters?.Filters,
          Query: filters?.Query,
          FilterConditions: filters?.FilterConditions
        });

        if (setRowData && data) {
          setUsers(data);
          setRowData(data.Users);
        }
      }
    } catch (err: any) {
      console.warn('fetchGetUsers ERROR', err);
    }
  }, []);

  useEffect(() => {
    const { isSuccess, isError, error } = resultChangeStatuses;
    const _errorStatus = (error as any)?.data;

    const message =
      editUser || num <= 1
        ? 'Статус был успешно обновлен'
        : 'Статусы пользователей успешно обновлены';

    universalNotification({
      isSuccess: isSuccess,
      isError: isError,
      error: _errorStatus,
      successMessage: message
    });
  }, [resultChangeStatuses]);

  return { changeStatuses };
};
