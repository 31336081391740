import { Container, Title, Text, Button, SimpleGrid, Box } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import { ErrorPageBackgroundImage } from '@assets/icons';

export function NotFound() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const goMainPage = () => {
    navigate('/');
  };

  return (
    <Container className={classes.root}>
      <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}>
        <Box className={classes.mobileImage}>
          <ErrorPageBackgroundImage />
        </Box>

        <div>
          <Title className={classes.title}>Что-то неправильно...</Title>
          <Text className={classes.description} color="dimmed">
            Страница, которую вы пытаетесь открыть, не существует. Возможно, вы ошиблись адресом или
            страница была перемещена на другой URL. Если вы считаете, что это ошибка, обратитесь в
            службу поддержки.
          </Text>
          <Button
            variant="outline"
            size="md"
            mt="xl"
            className={classes.control}
            onClick={goMainPage}
          >
            Вернуться на главную страницу
          </Button>
        </div>

        <Box className={classes.desktopImage}>
          <ErrorPageBackgroundImage />
        </Box>
      </SimpleGrid>
    </Container>
  );
}
