import { Button, Text, Box, TextInput, SimpleGrid, UnstyledButton } from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons';
import { Modal } from '@/components/modal';
import { SelectWithPagination } from '@/containers/pages/filials/components/select';
import { useCreateFilialMutation } from '@/entities/admin-app/filials/api';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../styles';
import { getValidateErrors } from '@/lib/utils/validation';
import { createFilialSchema } from '@/containers/pages/filials/schemes/validate';
import { clearApiCacheByTags } from '@/entities/helpers';
import { rolesApi } from '@/entities/admin-app/roles/api';

interface ICreateFilial {
  Company?: string;
  Title: string;
  Parent: {
    Id: number | string;
  };
}
export const CreateFilial = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [createFilial] = useCreateFilialMutation();

  const form = useForm<ICreateFilial>({
    initialValues: {
      Company: '',
      Title: '',
      Parent: {
        Id: ''
      }
    },
    validateInputOnChange: true,
    validate: (values: ICreateFilial): FormErrors => getValidateErrors(createFilialSchema, values)
  });

  const handleClose = () => {
    close();
    form.reset();
  };

  // To Do fix Types
  const handleSubmit = async (values: any) => {
    const filialValues = {
      ...values,
      Title: values.Title.trim()
    };
    try {
      const res = await createFilial(filialValues);

      if ('error' in res) {
        showNotification({
          title: `${(res as any)?.error?.data?.ErrorMessage}`,
          message: '',
          autoClose: true,
          color: 'red',
          style: { zIndex: 1003 }
        });
      } else {
        showNotification({
          title: t('filials.filialSuccess'),
          message: '',
          autoClose: true,
          color: 'green',
          style: { zIndex: 1003 }
        });
        handleClose();
        clearApiCacheByTags(rolesApi, ['Roles']);
      }
    } catch (err: any) {
      console.warn(t('filials.filialError'), err);
    }
  };

  return (
    <>
      <Button
        size="md"
        leftIcon={<IconPlus />}
        className={classes.createButton}
        onClick={open}
        styles={{
          leftIcon: { marginRight: '0' }
        }}
      >
        <Text className={classes.createButtonText}> {t('create')}</Text>
      </Button>

      <Modal opened={opened} onClose={close} title={t('filials.create')} zIndex={1002}>
        <form className={classes.modalRoot} onSubmit={form.onSubmit(handleSubmit)}>
          <Box className={classes.modalContainer}>
            <SimpleGrid cols={1} w="100%">
              <TextInput
                placeholder={t('filials.settings.filialName')}
                label={t('filials.addName')}
                withAsterisk
                data-value="Title"
                {...form.getInputProps('Title')}
              />
              <TextInput
                placeholder={t('filials.settings.filialCode')}
                label={t('filials.settings.filialCode')}
                data-value="Company"
                {...form.getInputProps('Company')}
              />

              <SelectWithPagination
                form={form}
                fieldName="Parent.Id"
                defaultValue={null}
                label={t('filials.addParrent')}
                placeholder={t('profile.choose')}
                value={+form?.getTransformedValues().Parent?.Id}
                list={[]}
              />
            </SimpleGrid>
          </Box>
          <Box className={classes.modalFooter}>
            <Button
              // color="red"
              size="sm"
              radius={8}
              w="49%"
              type="submit"
              disabled={!form.isValid()}
            >
              {t('create')}
            </Button>
            <UnstyledButton ml={8} className={classes.modalCancelBtn} w="49%" onClick={handleClose}>
              {t('cancel')}
            </UnstyledButton>
          </Box>
        </form>
      </Modal>
    </>
  );
};
