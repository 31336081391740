import { userTask } from '@/api/user';
import { useLazyCurrentUserQuery } from '@/entities/admin-app/user/api';
import { useLazyGetUserInfoQuery } from '@/entities/admin-app/user/api';
import { UserTask } from '@/types/enums/userTask';
import { useParams } from 'react-router-dom';
import { useActions } from '@/hooks/redux/action';
import { useAppSelector } from '@/hooks/redux/redux';
import { IUser } from '@/entities/admin-app/auth';
import { generateUUID } from '@/utils/uuid';
import { useContext } from 'react';
import { EditUserInfoContext } from '../..';
import { usersApi } from '@/entities/admin-app/users/api';
import { clearApiCacheByTags } from '@/entities/helpers';

const useUserTask = () => {
  const [fetchUser] = useLazyCurrentUserQuery();
  const [fetchUserInfo] = useLazyGetUserInfoQuery();
  const params = useParams();
  const { setUserInfo } = useActions();
  const { currentId, setAvatarKey } = useContext(EditUserInfoContext);
  const { User } = useAppSelector((state) => state.userInfo);

  return async (taskId: number) => {
    const userId = Number(params.id);
    if (taskId) {
      let count = 0;
      const result = await userTask(taskId);

      if (UserTask.Done !== result.data.Status) {
        const intervalID = setInterval(async () => {
          const repeatRequest = await userTask(taskId);
          count++;
          if (count >= 8) {
            clearInterval(intervalID);
          }

          if (UserTask.Done === repeatRequest.data.Status) {
            const uuid = generateUUID();
            if (User?.Id === currentId) {
              setUserInfo({
                ...User,
                HasAvatar: true,
                AvatarKey: uuid
              } as IUser);
            } else {
              setAvatarKey(uuid);
            }
            clearApiCacheByTags(usersApi, ['Users']);
            clearInterval(intervalID);
            fetchUser();
            fetchUserInfo(userId);
          }
        }, 800);
      }
    }
  };
};

export default useUserTask;
