import { z } from 'zod';
import i18n from '@/entities/i18next';
import { limitsLengthRolesFields } from '@/containers/pages/roles/utils';

export const validateCreateRolesSchema = z.object({
  Name: z
    .string()
    .max(limitsLengthRolesFields.Name, {
      message: i18n.t('validation.maxLengthSymbols', { count: limitsLengthRolesFields.Name })
    })
    .refine((data) => /\S/.test(data), {
      message: i18n.t('validation.enterRolesName')
    }),
  Customer: z.object({
    Id: z.number({ invalid_type_error: i18n.t('validation.required') })
  })
});
