import React, { FC, useState } from 'react';
import { ActionIcon, Badge, Box, Divider, Group, Menu, Text, UnstyledButton } from '@mantine/core';
import {
  IconAdjustmentsHorizontal,
  IconArrowLeft,
  IconBackspace,
  IconChevronDown
} from '@tabler/icons';
import { getActiveFiltersLengths } from '../../../utils';
import { themeColors } from '@/theme/colors';
import { useStyles } from './styles';
import { UsersFiltersModal } from '../../modals/filters';
import { SearchFilter } from '@components/filters/search-filter';
import { DateRangeFilter } from '@components/filters/date-range-filter';
import { IFilterMenu } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';
import { IChangeFilterData } from '@/entities/admin-app/users';
import { useGetFilterListItemsQuery } from '@/entities/admin-app/users/api';
import { useTranslation } from 'react-i18next';

interface IFilterListProps {
  activeFilters: Record<number, number[] | Date[]>;
  handleFilterChange: (data: IChangeFilterData) => void;
  handleFilterReset: (value: IFiltersEnumType) => void;
  handleResetFilters: () => void;
  filtersMenuList: IFilterMenu[];
}

const UsersFilterList: FC<IFilterListProps> = ({
  activeFilters,
  handleFilterReset,
  filtersMenuList,
  handleResetFilters,
  handleFilterChange
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [openFiltersModal, setOpenFiltersModal] = useState<boolean>(false);
  const totalActiveFiltersCount = getActiveFiltersLengths(activeFilters);
  const slicedFilters = [...filtersMenuList.slice(0, 4)];

  if (!filtersMenuList) {
    return null;
  }
  //TODO Удалить Когда будет исправлено на бэкенде
  const excludedTypes = [5, 6, 7, 9, 13, 14];
  const filteredMenuList = filtersMenuList.filter((filterMenu: { Type: number }) => {
    return !excludedTypes.includes(filterMenu.Type);
  });
  //TODO Вернуть как было когда всё будет исправлено {filtersMenuList?.length && (

  return (
    <Box className={classes.filtersListWrapper}>
      <Box className={classes.leftSection}>
        {slicedFilters.map((filterMenu, index) => {
          const activeIds = activeFilters[filterMenu?.Type] as number[];
          return (
            <Menu
              width="auto"
              zIndex={400}
              position="bottom-start"
              key={`${filterMenu?.label}${index}${filterMenu.Type}`}
            >
              <Menu.Target>
                <Badge
                  className={classes.filterHeader}
                  rightSection={
                    activeIds?.length ? (
                      <Box className={classes.badgeContainer}>
                        <Badge className={classes.badge} variant="filled">
                          {activeIds?.length}
                        </Badge>

                        <ActionIcon size={24} onClick={() => handleFilterReset(filterMenu.Type)}>
                          <IconBackspace size={16} color={themeColors.brandGray[0]} />
                        </ActionIcon>
                      </Box>
                    ) : null
                  }
                >
                  <UnstyledButton className={classes.menuButton}>
                    {filterMenu?.label || t('users.status')} <IconChevronDown size={18} />
                  </UnstyledButton>
                </Badge>
              </Menu.Target>
              <Menu.Dropdown>
                <SearchFilter
                  handleFilterReset={handleFilterReset}
                  activeIds={activeIds}
                  filterType={filterMenu?.Type}
                  label={filterMenu?.label}
                  list={filterMenu?.Items}
                  hideSearch={filterMenu?.hideSearch}
                  hideCollapse={true}
                  handleFilterChange={handleFilterChange}
                  handleLoadData={useGetFilterListItemsQuery}
                />
              </Menu.Dropdown>
            </Menu>
          );
        })}
        {/* {filtersMenuList?.length && ( */}
        {filteredMenuList?.length ? (
          <Badge
            className={classes.filterHeader}
            rightSection={
              totalActiveFiltersCount ? (
                <Box className={classes.badgeContainer}>
                  <Badge className={classes.badge} variant="filled">
                    {totalActiveFiltersCount}
                  </Badge>

                  <ActionIcon size={24} onClick={handleResetFilters}>
                    <IconBackspace size={16} color={themeColors.brandGray[0]} />
                  </ActionIcon>
                </Box>
              ) : null
            }
          >
            <UnstyledButton onClick={() => setOpenFiltersModal(!openFiltersModal)}>
              <Group className={classes.filterItem}>
                <IconAdjustmentsHorizontal size={16} />
                <div>
                  <Text>{t('users.allFilters')}</Text>
                </div>
              </Group>
            </UnstyledButton>
          </Badge>
        ) : null}
        <UsersFiltersModal
          list={
            <>
              <Group>
                <ActionIcon
                  onClick={() => setOpenFiltersModal(!openFiltersModal)}
                  size="md"
                  className={classes.backArrow}
                >
                  <IconArrowLeft color="#667085" />
                </ActionIcon>
                <Divider />
              </Group>
              {/* {filtersMenuList.map((filterMenu, index) => { */}
              {filteredMenuList.map((filterMenu, index) => {
                const activeIds = activeFilters[filterMenu?.Type] as number[];
                return (
                  <Group key={`${filterMenu?.Type}${index}${filterMenu?.label}`}>
                    <SearchFilter
                      handleFilterReset={handleFilterReset}
                      activeIds={activeIds}
                      filterType={filterMenu?.Type}
                      list={filterMenu?.Items}
                      label={filterMenu?.label}
                      hideSearch={filterMenu?.hideSearch}
                      handleFilterChange={handleFilterChange}
                      handleLoadData={useGetFilterListItemsQuery}
                    />
                    <Divider />
                  </Group>
                );
              })}
              <Group>
                <DateRangeFilter
                  activeValue={activeFilters[IFiltersEnumType.BirthDateFilter] as [Date, Date]}
                  type={IFiltersEnumType.BirthDateFilter}
                  handleFilterReset={handleFilterReset}
                  handleChange={handleFilterChange}
                  maxDate={new Date()}
                />
                <Divider />
              </Group>

              <Group>
                <DateRangeFilter
                  activeValue={activeFilters[IFiltersEnumType.DateOfCreateFilter] as [Date, Date]}
                  type={IFiltersEnumType.DateOfCreateFilter}
                  handleFilterReset={handleFilterReset}
                  handleChange={handleFilterChange}
                  maxDate={new Date()}
                />
                <Divider />
              </Group>

              <Group>
                <DateRangeFilter
                  activeValue={activeFilters[IFiltersEnumType.LastVisitDateFilter] as [Date, Date]}
                  type={IFiltersEnumType.LastVisitDateFilter}
                  handleFilterReset={handleFilterReset}
                  handleChange={handleFilterChange}
                  maxDate={new Date()}
                />
                <Divider />
              </Group>
              {/* Вернуть когда исправят на бэкенде */}
              {/* <Group>
              <NumberRangeFilter
                activeValue={activeFilters[IFiltersEnumType.UsingQuotaSizeFilter] as number[]}
                type={IFiltersEnumType.UsingQuotaSizeFilter}
                handleFilterReset={handleFilterReset}
                handleChange={handleFilterChange}
              />
            </Group> */}
            </>
          }
          open={openFiltersModal}
          setOpen={() => setOpenFiltersModal(!openFiltersModal)}
        />
      </Box>
    </Box>
  );
};

export default React.memo(UsersFilterList);
