export const ROUTES = {
  users: {
    path: '/users',
    fullPath: '/users',
    links: {
      path: '/links',
      fullPath: '/users/links',
      link: {
        path: '/:id',
        fullPath: '/users/links/:id'
      },
      createLink: {
        path: '/create-link',
        fullPath: '/users/links/create-link'
      }
    },
    delete: {
      path: '/delete',
      fullPath: '/users/delete'
    },
    roles: {
      path: '/roles',
      fullPath: '/roles'
    }
  },

  roles: {
    path: '/roles',
    fullPath: '/roles'
  },

  filials: {
    path: '/filials',
    fullPath: '/filials',
    edit: {
      path: ':id',
      fullPath: '/filials/:id',
      pages: {
        common: {
          settings: '',
          security: 'security',
          smtp: 'smtp',
          importUsers: 'import-users',
          mailServers: 'mail-servers',
          mailingSetup: 'mailing-setup',
          diskSpace: 'disk-space'
        },
        auth: {
          ldap: 'ldap',
          invite: 'invite'
        },
        modules: {
          // drive: 'drive',
          email: 'email',
          calendar: 'calendar',
          draw: 'draw',
          contacts: 'contacts'
        }
      }
    }
  },

  profile: {
    path: '/profile',
    fullPath: '/profile/:id',
    pages: {
      personalData: '',
      filials: 'filials',
      safety: 'safety',
      mailAccount: 'mail-account',
      disk: 'disk'
    }
  },

  dashboard: {
    fullPath: '/dashboard'
  },

  statistics: {
    path: '/statistics',
    fullPath: '/statistics',
    links: {
      activity: '',
      diskSpace: 'disk-space',
      audit: 'audit'
    }
  },
  commonSettings: {
    path: '/settings',
    fullPath: '/settings',
    pages: {
      branding: 'branding',
      customisation: 'customisation',
      integrations: ''
    }
  }
};
