import { createStyles } from '@mantine/core';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme) => ({
  modalRoot: modalTheme(theme).root,
  modalContainer: modalTheme(theme).container,
  modalFooter: modalTheme(theme).footer,
  modalCancelBtn: modalTheme(theme).cancelBtn,

  container: {
    // backgroundColor: theme.colors.backgroundGray[0],
    paddingRight: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    flexGrow: 1,
    overflow: 'auto',
    maxWidth: '850px',
    [theme.fn.smallerThan('md')]: {
      padding: '0 15px 20px'
    }
  },

  error: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.colors.backgroundGray[0],
    '& p': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0px 10px'
    }
  },

  section: {
    backgroundColor: theme.white,
    borderRadius: theme.spacing.xs,
    marginBottom: theme.spacing.sm,
    paddingBottom: theme.spacing.md,
    paddingRight: theme.spacing.sm,
    paddingLeft: theme.spacing.sm
  },

  listRow: {
    width: '100%'
  },

  phoneIcon: {
    position: 'absolute',
    right: '-50px',
    top: '30px'
  },

  phoneList: {
    width: '80%',
    position: 'relative'
  },

  title: {
    fontWeight: 600,
    marginTop: theme.spacing.sm,
    marginBottom: theme.spacing.sm
  },

  link: {
    // color: theme.colors.mainBlue[9]
  },

  radio: {
    padding: '10px 0',

    '& .mantine-Radio-inner': {
      alignSelf: 'center'
    },

    '& .mantine-Radio-label': {
      fontSize: '14px',
      paddingLeft: theme.spacing.xs
    }
  },

  checkbox: {
    '& .mantine-Checkbox-label': {
      paddingLeft: theme.spacing.xs
    }
  },

  checkboxItem: {
    padding: '12px 0',
    '& .mantine-Checkbox-root, .mantine-Checkbox-body, .mantine-Checkbox-labelWrapper': {
      fontSize: '14px'
    },
    '& .mantine-Checkbox-label': {
      paddingLeft: theme.spacing.xs
    }
  },

  rightSideContainer: {
    '& .mantine-InputWrapper-root, .mantine-NumberInput-root': {
      marginBottom: '0'
    },

    '& .mantine-Select-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    }
  },

  textFieldInput: {
    width: '220px',
    '& .mantine-TextInput-label, .mantine-InputWrapper-label, .mantine-DatePickerInput-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    },

    '& .mantine-TextInput-input, .mantine-NumberInput-input, .mantine-Textarea-input, .mantine-PasswordInput-input, .mantine-Select-input, .mantine-DatePickerInput-input':
      {
        borderRadius: '8px',
        border: '1px solid #D0D5DD'
      },
    [theme.fn.smallerThan('md')]: {
      marginRight: 0,
      maxWidth: 'auto'
    }
  },
  dateFieldInput: {
    '& .mantine-DatePickerInput-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    },

    '& .mantine-DatePickerInput-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD',
      cursor: 'pointer'
    },

    [theme.fn.smallerThan('md')]: {
      marginRight: 0,
      maxWidth: 'auto'
    }
  },

  addButton: {
    borderRadius: '8px',
    border: `1px solid ${theme.colors.brandGray[0]}`,
    '&:hover': {
      backgroundColor: '#f6f7f8'
    },

    '& .mantine-Button-inner': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    '& .mantine-Button-label': {
      color: theme.black
    }
  },

  removeButton: {
    marginBottom: '2px',
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'flex-end'
  },

  description: {
    color: theme.colors.brandGray[0],
    fontSize: '14px',
    lineHeight: '17.5px'
  },
  red: {
    color: '#fa5252'
  },
  label: {
    fontSize: '14px',
    paddingTop: '20px'
  },

  groupGrid: {
    alignSelf: 'stretch',
    padding: '0 20px'
  },
  mailText: {
    borderRadius: '8px',
    border: `1px solid ${theme.colors.brandGray[0]}`,
    width: '40%',
    padding: '4px',
    paddingLeft: '16px',
    marginRight: '24px'
  },

  rotate: {
    animation: 'rotate 3s linear infinite',

    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(360deg)'
      },
      '100%': {
        transform: 'rotate(0deg)'
      }
    }
  },

  textInput: {
    '& .mantine-TextInput-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    }
  },
  linkInput: {
    maxWidth: '400px',
    width: '100%',
    '& .mantine-TextInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    },

    '& .mantine-TextInput-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    },
    '& .mantine-TextInput-rightSection': {
      color: '#175CD3',
      cursor: 'pointer'
    }
  },
  tableHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',
    zIndex: 300
  },

  scrolled: {
    boxShadow: theme.shadows.sm
  },

  infiniteScrollMoreData: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  infiniteScroll: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#98A2B3'
    },

    '&::-webkit-scrollbar-track': {
      background: '#F9FAFB'
    },

    scrollbarColor: '#98A2B3 #F9FAFB',
    width: '100%'
  }

  // container: {
  //   flexGrow: 1,
  //   backgroundColor: theme.colors.backgroundGray[0],
  //   paddingRight: theme.spacing.sm,
  //   paddingBottom: theme.spacing.sm,
  //   overflow: 'auto',
  //   maxWidth: '850px',
  //   paddingLeft: `8px`
  // },
  // section: {
  //   overflow: 'auto',
  //   alignSelf: 'stretch',
  //   backgroundColor: 'white',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   position: 'relative',
  //   borderRadius: theme.spacing.xs
  // },
  // description: {
  //   color: theme.colors.brandGray[0]
  // },
  //
  // // header: {
  // //   margin: 0
  // // },
  //
  // header: {
  //   position: 'sticky',
  //   top: 0,
  //   backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  //   transition: 'box-shadow 150ms ease',
  //   zIndex: 300
  // },

  //
  // addButton: {
  //   borderRadius: '8px',
  //   border: `1px solid ${theme.colors.brandGray[0]}`,
  //
  //   '&:hover': {
  //     backgroundColor: '#f6f7f8'
  //   },
  //
  //   '& .mantine-Button-inner': {
  //     display: 'flex',
  //     flexDirection: 'row',
  //     alignItems: 'center',
  //     justifyContent: 'space-between'
  //   },
  //
  //   '& .mantine-Button-label': {
  //     color: theme.black
  //   },
  //
  //   [theme.fn.largerThan('xs')]: {
  //     width: '224px'
  //   }
  // },
}));
