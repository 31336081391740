import { ErrorBoundary } from '@components/error-boundary';
import { useAppSelector } from '@/hooks/redux/redux';
import CommonModuleContainer from '@/containers/pages/filials/edit/components/common/modules';
import { ModulesEnum } from '@/types/enums/filials';

const ContactsModule = () => {
  const { filial } = useAppSelector((state) => state.filials);

  return (
    <ErrorBoundary componentTitle="Contacts odule page">
      <CommonModuleContainer filial={filial} currentModule={ModulesEnum.Contacts} />
    </ErrorBoundary>
  );
};

export default ContactsModule;
