import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  heading: {
    color: theme.colors.brandGray[0],
    lineHeight: '17.5px',
    padding: '12px 0',
    '@media (max-width: 992px)': {
      display: 'none'
    }
  },

  navLinkItem: {
    '&.mantine-NavLink-root': {
      borderRadius: '8px',
      '&[data-active=true]': {
        backgroundColor: theme.colors.brandGray[1],
        fontWeight: 'bold',
        color: '#101828'
      },
      [theme.fn.smallerThan('md')]: {
        paddingRight: '8px',
        paddingLeft: '8px'
      }
    }
  }
}));
