import { FC } from 'react';
import { SimpleGrid } from '@mantine/core';
import { SectionTemplate } from '../../../../../user/components/section-template';
import EmailCredentials from '@/containers/pages/user/edit-user/components/sections/user-credentials/email-credentionals';
import LoginCredentials from '@/containers/pages/user/edit-user/components/sections/user-credentials/login-credentionals';
import { PanelsEnum } from '@/types/enums/user';
import { useMediaQuery } from '@mantine/hooks';
import { isSaaS } from '@/lib/utils/access';
import { useAppSelector } from '@/hooks/redux/redux';
import AdditionalEmailCredentials from '@/containers/pages/user/edit-user/components/sections/user-credentials/additional-email-credentionals';

const UserCredentials: FC = () => {
  const isMobile = useMediaQuery('(max-width: 576px)');
  const { Settings } = useAppSelector((state) => state.userInfo);

  return (
    <SectionTemplate dataSection={PanelsEnum.credentials} title="Учетные данные">
      <SimpleGrid cols={isMobile ? 1 : 2} w="100%">
        {!isSaaS(Settings) ? <LoginCredentials /> : null}
        <EmailCredentials />
        <AdditionalEmailCredentials />
      </SimpleGrid>
    </SectionTemplate>
  );
};

export default UserCredentials;
