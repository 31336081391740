import { showNotification } from '@mantine/notifications';
import { useLazyLoginAsQuery } from '@/entities/admin-app/auth/api';
import { useCallback } from 'react';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { ROUTES } from '@/containers/routes/constants';

interface ILoginAsProps {
  Login: string;
  LoginAs: string;
  Password: string;
  handleUpdate?: () => void;
}
export const useLoginAs = () => {
  const [loginAsRequest, { isFetching: isFetchingLoginAs }] = useLazyLoginAsQuery();
  const handleLoginAs = useCallback(
    async ({ Login, LoginAs, Password, handleUpdate }: ILoginAsProps) => {
      try {
        const res = await loginAsRequest({
          Login,
          LoginAs,
          Password
        });
        if ('error' in res) {
          const codeError = extractErrorCode(res.error);
          showNotification({
            title: generateErrorMessage(codeError),
            message: '',
            autoClose: true,
            color: 'red',
            style: { zIndex: 1003 }
          });
        } else {
          window.location.href = ROUTES.dashboard.fullPath;
          if (handleUpdate) {
            handleUpdate();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  return { handleLoginAs, isFetchingLoginAs };
};
