import { useState } from 'react';
import { IColumnData, IRowData } from '@components/simple-table/types';
import moment from 'moment';

import { formatDate } from '@/lib/utils/date';

import { ItemTitle as ItemTitle } from '../components/table/item-title';
import { Action } from '../components/table/action';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';

export const useGetColumns = () => {
  const { Settings } = useAppSelector((state) => state.userInfo);
  const columnsData: IColumnData[] = [
    {
      width: '300px',
      dataIndex: 'Title',
      title: 'Название',
      hideColumn: false,
      render: (item: IRowData) => <ItemTitle item={item} />
    },
    {
      width: '120px',
      dataIndex: 'Shortname',
      title: 'Код подразделения',
      hideColumn: false,
      render: (item: IRowData) => item?.Company
    },
    {
      width: '246px',
      dataIndex: 'Company',
      title: 'Родительское подразделение',
      hideColumn: false,
      render: (item: IRowData) => item?.ParentName
    },
    {
      width: '146px',
      dataIndex: 'UsersCount',
      title: 'Пользователей',
      hideColumn: false,
      render: (item: IRowData) => item?.UsersCount
    },
    {
      width: '146px',
      dataIndex: 'RolesCount',
      title: 'Ролей',
      hideColumn: false,
      render: (item: IRowData) => item?.RolesCount
    },
    {
      width: '146px',
      dataIndex: 'DateOfCreate',
      title: 'Дата создания',
      hideColumn: false,
      render: (item: IRowData) => formatDate(moment.utc(item?.DateOfCreate))
    },
    {
      width: '50px',
      dataIndex: 'Action',
      title: '',
      hideColumn: false,
      render: (item: IRowData) => <Action item={item} />
    }
  ].filter((item) => !isSaaS(Settings) || !['Shortname', 'Company'].includes(item.dataIndex));

  const [columns, setColumns] = useState<IColumnData[]>(columnsData);

  return { columns, setColumns };
};
