import { ITokens, IUser } from '../admin-app/auth';
import { showNotification } from '@mantine/notifications';
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { userInfoActions } from '../admin-app/user';
import i18n from '@/entities/i18next';

const statusErrorExceptions: Record<number, string[]> = {
  406: ['changePasswordLinkedUserEmail', 'commonSettings', 'getFilialsInfoCommon']
};
export const errorHandlingMiddleware: Middleware = (store) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { payload, meta } = action;
    const { status } = payload;
    const {
      arg: { originalArgs }
    } = meta;

    switch (status) {
      case 401:
        if (!originalArgs?.LoginAs) {
          store.dispatch(userInfoActions.setUserTokens({} as ITokens));
          store.dispatch(userInfoActions.setUserInfo({} as IUser));
          showNotification({
            title: i18n.t('login.errors.loginError'),
            message: i18n.t('login.errors.incorectNamePassword'),
            autoClose: true,
            color: 'red'
          });
        }

        // window.location.reload();

        break;
      case 403:
        showNotification({
          title: `${i18n.t('requestsErrors.error')} ${status}`,
          message: i18n.t('login.errors.blockedAccount'),
          autoClose: true,
          color: 'red'
        });
        break;
      case 406:
        if (!statusErrorExceptions[406].includes(action.meta.arg.endpointName)) {
          showNotification({
            title: i18n.t('login.errors.loginError'),
            message: i18n.t('login.errors.incorectNamePassword'),
            autoClose: true,
            color: 'red'
          });
        }
        break;
      case 429:
        showNotification({
          title: `${i18n.t('requestsErrors.error')} ${status}`,
          message: i18n.t('login.errors.limit'),
          autoClose: true,
          color: 'red'
        });
        break;
      case 404:
        showNotification({
          title: `${i18n.t('requestsErrors.error')} ${status}`,
          message: i18n.t('login.errors.notFound'),
          autoClose: true,
          color: 'red'
        });
        break;
      // case 409:
      //   showNotification({
      //     title: `Ошибка ${status}`,
      //     message: data.ErrorMessage ?? '',
      //     autoClose: false,
      //     color: 'red'
      //   });
      //   break;
      case 400:
        // case 500:
        //   if (action.meta.arg.endpointName !== 'resetPassword') {
        //     showNotification({
        //       title: `Ошибка ${status}`,
        //       message: data?.ErrorMessage ?? data?.Response?.Data,
        //       autoClose: 10_000,
        //       color: 'red'
        //     });
        //   }
        break;
      case 500:
        showNotification({
          title: i18n.t('login.errors.internalError'),
          message: i18n.t('login.errors.tryLater'),
          autoClose: true,
          color: 'red'
        });
        break;
      default:
        break;
    }
  }

  return next(action);
};
