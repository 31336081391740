import React from 'react';
import { useLazyDeleteLinkedUserEmailQuery } from '@/entities/admin-app/email/api';
import { Box, Button, Modal, SimpleGrid, Text } from '@mantine/core';
import { ILinkedEmail } from '@/entities/admin-app/email';
import { showNotification } from '@mantine/notifications';
import { useStyles } from '@/containers/pages/user/components/sections/linked-emails/styles';
import { useUpdateInfo } from '@/hooks/users/useUpdateUser';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { useTranslation } from 'react-i18next';

interface IDeleteEmailModalProps {
  opened: boolean;
  handleClose: () => void;
  selectedEmail: ILinkedEmail | null;
  editUser?: boolean;
  currentId?: number;
}
export const DeleteEmailModal = ({
  selectedEmail,
  opened,
  handleClose,
  editUser,
  currentId
}: IDeleteEmailModalProps) => {
  const { t } = useTranslation();
  const [deleteEmail] = useLazyDeleteLinkedUserEmailQuery();
  const { classes } = useStyles();
  const { saveUpdate } = useUpdateInfo();
  const modalClose = () => {
    handleClose();
  };

  const handleDeleteEmail = async () => {
    if (editUser && currentId) {
      await saveUpdate({
        Type: ProfileUpdateTypeEnum.Email,
        Data: { [ProfileSectionEnum.LinkedEmail]: [], Id: currentId }
      });

      modalClose();
    } else {
      try {
        const res = await deleteEmail(selectedEmail?.Id || 0);

        if ('error' in res) {
          showNotification({
            title: `${(res as any)?.error?.data?.ErrorMessage ?? ''}`,
            message: '',
            autoClose: true,
            color: 'red',
            style: { zIndex: 1003 }
          });
        } else {
          showNotification({
            title: t('profile.deletedSuccessMessage'),
            message: '',
            autoClose: true,
            color: 'green',
            style: { zIndex: 1003 }
          });

          modalClose();
        }
      } catch (err: any) {
        console.warn('Ошибка удаления', err);
      }
    }
  };
  return (
    <Modal
      opened={opened}
      onClose={modalClose}
      title={<Text className={classes.title}>{t('profile.deleteMailAccountTitle')}</Text>}
      zIndex={1300}
    >
      <Box mb="1rem">
        <Text mb="1rem" fz="md">
          {t('profile.areYouSureToDelete')} {selectedEmail?.Email} ?
        </Text>
      </Box>
      <SimpleGrid cols={2} mt="md" breakpoints={[{ maxWidth: 'xs', cols: 1 }]}>
        <Button color="gray" onClick={modalClose}>
          {t('profile.cancel')}
        </Button>
        <Button color="red" onClick={handleDeleteEmail}>
          {t('profile.untie')}
        </Button>
      </SimpleGrid>
    </Modal>
  );
};
