import { FC, useCallback, useContext } from 'react';
import { ActionIcon, Box } from '@mantine/core';
import { PanelsEnum } from '@/types/enums/roles';
import { EditIcon } from '@assets/icons';
import { RolesInfoMainContext } from '../../panels';
import { useStyles } from './styles';
import { Action } from '../../../Table/action';
import { IconArrowLeft } from '@tabler/icons';
import { useActions } from '@/hooks/redux/action';
import { canEditRole } from '@/lib/utils/access';

export const DrawerHeader: FC = () => {
  const { classes } = useStyles();
  const { rolesInfoCommon } = useContext(RolesInfoMainContext);
  const { setSelectedRolesId, setSelectedPanel } = useActions();

  const Type = rolesInfoCommon?.Type;
  const goFilialUser = () => {
    setSelectedPanel(PanelsEnum.editInformation);
  };

  const goBack = useCallback(() => {
    setSelectedRolesId(0);
  }, []);

  return (
    <>
      <ActionIcon onClick={goBack} size="md" className={classes.backArrow}>
        <IconArrowLeft color="#667085" />
      </ActionIcon>
      <Box className={classes.action}>
        {canEditRole(Type) ? (
          <ActionIcon size="md" onClick={goFilialUser}>
            <EditIcon />
          </ActionIcon>
        ) : null}
        {canEditRole(Type) ? <Action item={rolesInfoCommon} /> : null}
      </Box>
    </>
  );
};
