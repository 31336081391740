import { ErrorsEnum } from '@/types/enums';

export function getRegistrationErrorKey(Code?: ErrorsEnum) {
  return Code === ErrorsEnum.UserIsExists
    ? 'registerUserExists'
    : Code === ErrorsEnum.InvalidPassword
    ? 'invalidPassword'
    : Code === ErrorsEnum.ObjectIsNotActive
    ? 'inviteRegisterLinkNoValid'
    : Code === ErrorsEnum.InvalidEmailFormat
    ? 'invalidRegistrationEmail'
    : 'unknownError';
}

export const limitsLengthRegistrationFields = {
  Company: 128
} as const;
