import React, { FC } from 'react';
import { ClassNames, Menu, MenuStylesNames } from '@mantine/core';
import { IComponentDefaultProps } from '@/types';
import { DragDrop } from '../drag-drop';
import { FloatingPosition } from '@mantine/core/lib/Floating';
import { IColumnData } from '../simple-table/types';

interface IDropdownMenuDndProps extends IComponentDefaultProps {
  list: IColumnData[];
  classNames?: ClassNames<MenuStylesNames>;
  position: FloatingPosition;
  width?: string | number;
  setColumnsData?: (columns: IColumnData[]) => void;
}

export const DropdownMenuDnd: FC<IDropdownMenuDndProps> = ({
  width = 200,
  list,
  position,
  classNames,
  setColumnsData,
  ...props
}) => {
  return (
    <Menu classNames={classNames} shadow="md" position={position} zIndex={400} width={width}>
      <Menu.Target>{props?.children}</Menu.Target>
      <Menu.Dropdown>
        <DragDrop data={list} setColumnsData={setColumnsData} />
      </Menu.Dropdown>
    </Menu>
  );
};
