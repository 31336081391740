import { FC, SyntheticEvent } from 'react';
import { IRowData } from '@components/simple-table/types';
import { Checkbox as DefaultChackbox } from '@mantine/core';

interface IActionProps {
  selection?: number[];
  toggleRow: (event: SyntheticEvent<HTMLInputElement>) => void;
  item: IRowData;
}

export const CustomCheckbox: FC<IActionProps> = ({ selection, toggleRow, item }) => {
  const handleClick = (event: SyntheticEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  return (
    <DefaultChackbox
      data-id={item?.Id}
      checked={selection && selection.includes(item?.Id)}
      onChange={toggleRow}
      onClick={handleClick}
      transitionDuration={0}
      sx={{
        '& input': {
          cursor: 'pointer'
        }
      }}
    />
  );
};
