import {
  GearIcon,
  ShieldIcon,
  MailIcon,
  SendIcon,
  UsersIcon,
  inviteUserIcon,
  DriveIcon
} from '@assets/icons';
import { ROUTES } from '@/containers/routes/constants';
import { FilialInfoPanelsEnum } from '@/types/enums/filials';
import { INavbarMenu } from '@/mock-data/navbar-menu';

export const menuCommonPages: INavbarMenu[] = [
  { label: 'Общие настройки', icon: GearIcon, value: ROUTES.filials.edit.pages.common.settings },
  { label: 'Авторизация', icon: UsersIcon, value: ROUTES.filials.edit.pages.auth.ldap },
  { label: 'Безопасность', icon: ShieldIcon, value: ROUTES.filials.edit.pages.common.security },
  // { label: 'Настройка SMTP', icon: MailIcon, value: ROUTES.filials.edit.pages.common.smtp },
  {
    label: 'Почтовые сервера',
    icon: MailIcon,
    value: ROUTES.filials.edit.pages.common.mailServers
  },
  {
    label: 'Настройка рассылки',
    icon: SendIcon,
    value: ROUTES.filials.edit.pages.common.mailingSetup
  },
  {
    label: 'Регистрация',
    icon: inviteUserIcon,
    value: ROUTES.filials.edit.pages.auth.invite
  },
  {
    label: 'Политика хранения',
    icon: DriveIcon,
    value: ROUTES.filials.edit.pages.common.diskSpace
  }
  // {
  //   label: 'Импорт пользователей',
  //   icon: LinkIcon,
  //   value: ROUTES.filials.edit.pages.common.importUsers
  // }
];

/*
export const menuAuthPages: readonly INavbarMenu[] = [
  { label: 'LDAP', icon: UsersIcon, value: ROUTES.filials.edit.pages.auth.ldap }
];
*/

const list = [
  { label: 'IP-безопасность пользователей', value: FilialInfoPanelsEnum.ipSecurityUsers },
  { label: 'IP-безопасность администрирования', value: FilialInfoPanelsEnum.ipSecurityAdm },
  { label: 'Время существования сессии', value: FilialInfoPanelsEnum.sessionDuration },
  { label: 'Ограничение попыток ввода пароля', value: FilialInfoPanelsEnum.passwordLimit }
  // {
  //   label: 'Настройки надежности пароля',
  //   value: FilialInfoPanelsEnum.passwordSecurity
  // }
];
// if (isFeature()) {
//   list.push({
//     label: 'Настройки надежности пароля',
//     value: FilialInfoPanelsEnum.passwordSecurity
//   });
// }
export const securityInfoSections = [...list] as const;

export enum emailServerConnectionType {
  None = 0,
  Auto = 1,
  SslOnConnect = 2,
  StartTls = 3,
  StartTlsWhenAvailable = 4
}

export const ConnectionTypeOptions = [
  {
    label: 'None',
    value: emailServerConnectionType.None.toString()
  },
  {
    label: 'Auto',
    value: emailServerConnectionType.Auto.toString()
  },
  {
    label: 'SslOnConnect',
    value: emailServerConnectionType.SslOnConnect.toString()
  },
  {
    label: 'StartTls',
    value: emailServerConnectionType.StartTls.toString()
  },
  {
    label: 'StartTlsWhenAvailable',
    value: emailServerConnectionType.StartTlsWhenAvailable.toString()
  }
];
