import { FC, createContext, useEffect, useRef } from 'react';
import { Loader, Select, SelectItem, Tooltip } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import useLoadMore from './hook/useLoadMore';

import { ErrorIcon } from './components/errorIcon';
import { DropDownComponent } from './components/dropdown';
import { useStyles } from './styles';

export interface ISelectProps {
  label?: string;
  placeholder: string;
  defaultValue?: number | null;
  value?: number | null;
  form?: UseFormReturnType<any>;
  handleChange?: (query: string) => void;
  fieldName: string;
  list: [];
  isCreateUser?: boolean;
  SkipCustomers?: number[];
}

interface LoadMoreResult {
  list: SelectItem[];
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  total: number;
  loadMore: () => void;
}

export const SelectWithPagination: FC<ISelectProps> = ({
  label,
  placeholder,
  defaultValue,
  value,
  form,
  handleChange,
  fieldName,
  isCreateUser,
  SkipCustomers
}) => {
  const { list, isLoading, isFetching, isError, total, loadMore }: LoadMoreResult = useLoadMore(
    defaultValue || value || undefined,
    SkipCustomers
  );
  const { classes } = useStyles();

  const handleChangeParentFilials = (query: string) => {
    if (form) {
      form?.setFieldValue(fieldName, query ? Number(query) : null);
      if (isCreateUser) {
        form?.setFieldValue('Quota.Id', 0);
      }
    } else {
      handleChange && handleChange(query);
    }
  };

  useEffect(() => {
    if (list.length && value === 0) {
      form?.setFieldValue(fieldName, list[0].value ? Number(list[0].value) : null);
      form?.resetDirty({ Customer: { Id: Number(list[0].value) } });
    }
  }, [list, value]);

  const ref = useRef<HTMLInputElement>(null);
  return (
    <SelectContext.Provider value={{ list, total, loadMore }}>
      {!isLoading && (
        <Select
          className={classes.select}
          data={list}
          ref={ref}
          required
          rightSectionWidth={30}
          label={label}
          defaultValue={String(defaultValue) || ''}
          value={`${value || ''}`}
          placeholder={placeholder}
          maxDropdownHeight={300}
          onChange={handleChangeParentFilials}
          dropdownComponent={DropDownComponent}
          error={form?.getInputProps(fieldName).error}
          rightSection={
            isFetching ? (
              <Loader size="xs" />
            ) : isError ? (
              <Tooltip color="red" label="Не удалось загрузить данные">
                <ErrorIcon />
              </Tooltip>
            ) : undefined
          }
        />
      )}
    </SelectContext.Provider>
  );
};

interface SelectContextType {
  list: SelectItem[];
  total: number;
  loadMore: () => void;
}
export const SelectContext = createContext<SelectContextType>({
  list: [],
  total: 0,
  loadMore: () => {
    // Default implementation does nothing
  }
});
