import React, { FC } from 'react';
import { Avatar, Badge, Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { getIndicatorByUserStatus } from '../../../../utils';
import { IUserInfo } from '@/entities/admin-app/user';

interface IPhotoInfoProps {
  userInfoCommon?: IUserInfo;
}

export const PhotoInfo: FC<IPhotoInfoProps> = ({ userInfoCommon }) => {
  const { classes } = useStyles();

  const { fullLabel, colorBadge } = getIndicatorByUserStatus(userInfoCommon?.Status ?? 0);

  return (
    <Box className={classes.photo}>
      <Avatar size={124} src={userInfoCommon?.Avatar} radius={62} />
      <Badge className={classes.badge} variant="light" color={colorBadge}>
        <Text>{fullLabel}</Text>
      </Badge>
      <Box className={classes.container} maw="100%">
        <Text className={classes.name} truncate>{`${userInfoCommon?.Common?.SurName || ''} ${
          userInfoCommon?.Common?.FirstName || ''
        } ${userInfoCommon?.Common?.MiddleName || ''}`}</Text>
        <Text className={classes.email} truncate>
          {userInfoCommon?.Credentials?.Email}
        </Text>
      </Box>
    </Box>
  );
};
