export enum SettingsEnum {
  UserIp = 1,
  AdminIp = 2,
  SessionDuration = 3,
  PassEnterCount = 4,
  Ldap = 5,
  Smtp = 6,
  PasswordSecurity = 7
}

export function setToEndOFDate(date: Date): string {
  return new Date(date.setHours(23, 59, 59, 999)).toISOString();
}

export const limitsLengthFilialFields = {
  Title: 128,
  Company: 30
} as const;
