import { Box } from '@mantine/core';
import { Dispatch, FC, RefObject, SetStateAction, SyntheticEvent, useCallback } from 'react';
import { userInfoSections } from '@/mock-data/navbar-menu';
import { PanelsEnum } from '@/types/enums/user';
import { useStyles } from './styles';

interface IInfoSectionProps {
  setActiveSection: Dispatch<SetStateAction<PanelsEnum>>;
  activeSection: PanelsEnum;
  containerRef: RefObject<HTMLDivElement>;
  isCreatePage?: boolean;
}

export const InfoSection: FC<IInfoSectionProps> = ({
  activeSection,
  setActiveSection,
  containerRef
}) => {
  const { classes, cx } = useStyles();

  const handleClick = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
    const value = event.currentTarget.dataset.value;

    setActiveSection(value as PanelsEnum);

    const targetElement = containerRef.current?.querySelector(`[data-section="${value}"]`);

    if (targetElement) {
      const headerHeight = 80;
      const scrollPosition =
        targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, []);

  const list = userInfoSections.filter((item) => item.value);

  return (
    <>
      {list.map((item) => {
        return (
          <Box
            className={cx(classes.item, {
              [classes.itemActive]: activeSection === item.value
            })}
            onClick={handleClick}
            key={item.value}
            data-value={item.value}
          >
            {item.label}
          </Box>
        );
      })}
    </>
  );
};
