import { FC, useState } from 'react';
import { Button, Text } from '@mantine/core';
import { IconPlus, IconX } from '@tabler/icons';
import { SelectUserModal } from '@components/modal/select-user';
import { IUserTableData } from '@/entities/admin-app/users';
import { useTranslation } from 'react-i18next';

interface ITransferData {
  nameButton?: string;
  userId: number;
  onChange: (id: number | null) => void;
}

export const TransferData: FC<ITransferData> = ({ nameButton = 'Выбрать', userId, onChange }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState<IUserTableData | null>(null);

  const buttonContent = selected ? (
    <>
      <Text fz="sm" fw="normal" mr={4}>
        {selected.FirstName || ''} {selected.MiddleName || ''}
      </Text>
      <IconX stroke={3} size={12} />
    </>
  ) : (
    <>
      <Text span mr="xs">
        {nameButton}
      </Text>
      <IconPlus opacity={0.6} size={16} />
    </>
  );

  const handleClick = () => {
    if (selected) {
      onChange(null);
      setSelected(null);
    } else {
      setShowModal(true);
    }
  };

  const handleSelectUser = (user: IUserTableData) => {
    onChange(user.Id);
    setSelected(user);
    setShowModal(false);
  };

  return (
    <>
      <Button
        variant="subtle"
        color="gray"
        h={28}
        px={12}
        c="#101828"
        bg="backgroundGray.6"
        radius="lg"
        onClick={handleClick}
      >
        {buttonContent}
      </Button>
      <SelectUserModal
        multipleUsers={false}
        isOpened={showModal}
        title={t('users.transferToUser')}
        userId={userId}
        setSelectedUsers={handleSelectUser}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};
