import { IUserTypeEnum } from '@/entities/admin-app/user';
import { UserStatusEnum } from '@/types/enums/user';

export const userTypeOptions = [
  { value: IUserTypeEnum.Contact.toString(), label: 'Локальный' },
  { value: IUserTypeEnum.LDAP.toString(), label: 'LDAP' },
  { value: IUserTypeEnum.SSO.toString(), label: 'SSO' }
];

export const userStatusesOptions = [
  {
    label: 'Активный',
    value: UserStatusEnum.Active.toString()
  },
  {
    label: 'Заблокированный',
    value: UserStatusEnum.Ban.toString()
  }
];
