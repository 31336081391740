import React, { FC, useCallback, useState } from 'react';
import { SimpleGrid, TextInput, Button, Box, UnstyledButton } from '@mantine/core';
import { useEditRolesMutation } from '@/entities/admin-app/roles/api';
import { useForm, FormErrors } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { SelectWithPagination } from '@/containers/pages/filials/components/select';
import { PanelsEnum } from '@/types/enums/roles';
import { IRolesInfoCommon } from '@/entities/admin-app/roles';
import { useActions } from '@hooks/redux/action';
import { IconPlus } from '@tabler/icons';
import { SelectGroupSimpleModal } from '@components/modal/select-group-simple';
import { SelectUserModal } from '@components/modal/select-user';
import { IUserTableData } from '@/entities/admin-app/users';
import { Badges } from '../../../badges';
import { themeColors } from '@/theme/colors';
import { useStyles } from '../../../create-item/styles';
import { transformObjectIfKeyIsNull } from '@/containers/pages/roles/utils';
import { getValidateErrors } from '@/lib/utils/validation';
import { validateCreateRolesSchema } from '@/containers/pages/roles/components/schemes/validate';
interface Props {
  editedItem?: IRolesInfoCommon;
}
export interface IBadges {
  Name: string;
}

export const EditRolesItem: FC<Props> = ({ editedItem }) => {
  const { classes } = useStyles();
  const { setSelectedRolesId, setSelectedPanel } = useActions();

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState<Pick<IUserTableData, 'Name' | 'Id'> | null>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    (editedItem?.Owner?.Name && { Name: editedItem?.Owner?.Name }) || null
  );
  const [selectedRoles, setSelectedRoles] = useState<IBadges | null>(
    (editedItem?.Parent?.Name && { Name: editedItem?.Parent?.Name as any }) || null
  );

  const closeModals = () => {
    setIsUserModalOpen(false);
    setIsRoleModalOpen(false);
  };

  const goBack = useCallback(() => {
    setSelectedPanel(PanelsEnum.generalInformation);
  }, []);

  const isMain = editedItem?.ParentName == null && editedItem?.Id == 1;
  const form = useForm({
    initialValues: {
      Id: editedItem?.Id || null,
      Customer: {
        Id: editedItem?.Customer?.Id || null
      },
      Type: 1,
      Name: editedItem?.Name,
      Parent: {
        Id: editedItem?.Parent?.Id || null
      },
      Owner: {
        Id: editedItem?.Owner?.Id || null
      }
    },
    validateInputOnChange: true,
    validate: (values: any): FormErrors => getValidateErrors(validateCreateRolesSchema, values)
  });

  const isDisabled = !form.isValid() || !form.isDirty();

  const [editRoles] = useEditRolesMutation();
  const handleSubmit = async (values: any) => {
    const formatted = transformObjectIfKeyIsNull(JSON.parse(JSON.stringify(values)));
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { Parent, ...IsMineData } = values;
      const res = await editRoles(!isMain ? formatted : IsMineData);

      if (!Object.keys(res).includes('error')) {
        handleClose();
        showNotification({
          title: `Роль успешно изменена!`,
          message: '',
          autoClose: true,
          color: 'green'
        });
      } else {
        showNotification({
          title: `${(res as any)?.error?.data}`,
          message: '',
          autoClose: true,
          color: 'red'
        });
      }
    } catch (err: any) {
      console.warn('Edit role ERROR', err);
    }
  };
  const setGroupId = async (id: number) => {
    form.setFieldValue('Parent.Id', id);
    closeModals();
  };

  const setGroup = async (group: any) => {
    setSelectedRoles({ Name: group?.label });
  };
  const handleClose = useCallback(() => {
    setSelectedPanel(PanelsEnum.generalInformation);
    setSelectedRolesId(0);
    form.reset();
  }, []);

  const handleBack = () => {
    goBack();
    form.reset();
  };

  const handleRemoveRoles = () => {
    setSelectedRoles(null);
    form.setFieldValue('Parent.Id', null);
  };

  const handleRemoveUser = () => {
    setSelectedUser(null);
    form.setFieldValue('Owner.Id', null);
  };

  const handleConfirmSelectUserModal = async () => {
    if (!selectedUser) {
      return;
    }
    form.setFieldValue('Owner.Id', selectedUser?.Id);
    closeModals();
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <SimpleGrid cols={1} w="100%" mt={24}>
        <TextInput
          placeholder="Admin"
          label="Имя роли"
          withAsterisk
          data-value="Name"
          {...form.getInputProps('Name')}
        />

        {!isMain && (
          <SelectWithPagination
            form={form}
            fieldName="Customer.Id"
            label="Добавить подразделение"
            placeholder="Выберите..."
            list={[]}
            defaultValue={form.getInputProps('Customer.Id').value || null}
            value={form.getInputProps('Customer.Id').value}
          />
        )}

        <SimpleGrid cols={1}>
          <Button
            className={classes.addButton}
            size="sm"
            variant="outline"
            rightIcon={<IconPlus color={themeColors.brandGray[0]} />}
            onClick={() => setIsRoleModalOpen(true)}
          >
            Добавить родительскую роль
          </Button>
          {selectedRoles && (
            <Box>
              <Badges label={selectedRoles?.Name} onRemove={handleRemoveRoles} />
            </Box>
          )}
        </SimpleGrid>
        <SimpleGrid cols={1}>
          <Button
            className={classes.addButton}
            size="sm"
            variant="outline"
            rightIcon={<IconPlus color={themeColors.brandGray[0]} />}
            onClick={() => setIsUserModalOpen(true)}
          >
            Добавить руководителя роли
          </Button>
          {selectedUser && (
            <Box>
              <Badges label={selectedUser?.Name} onRemove={handleRemoveUser} />
            </Box>
          )}
        </SimpleGrid>
      </SimpleGrid>

      <SimpleGrid cols={2} mt="md" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        <Button type="submit" disabled={isDisabled}>
          Сохранить
        </Button>
        <UnstyledButton onClick={handleBack} className={classes.cancelBtn}>
          Отмена
        </UnstyledButton>
      </SimpleGrid>
      <SelectGroupSimpleModal
        onClose={closeModals}
        isOpened={isRoleModalOpen}
        title="Добавить родительскую роль"
        setGroupId={setGroupId}
        setGroup={setGroup}
        SkipIds={editedItem?.Id ? [editedItem?.Id] : []}
      />
      <SelectUserModal
        multipleUsers={false}
        isOpened={isUserModalOpen}
        title="Добавить руководителя роли"
        onClose={closeModals}
        setSelectedUsers={setSelectedUser}
        userId={selectedUser?.Id}
        controlSection
        controlSectionProps={{
          confirmProps: {
            btnName: 'Добавить',
            fn: handleConfirmSelectUserModal
          },
          cancelProps: {
            btnName: 'Отмена',
            fn: closeModals
          }
        }}
      />
    </form>
  );
};
