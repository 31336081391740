import { useCallback } from 'react';
import { useUpdateUserInfoMutation } from '@/entities/admin-app/user/api';
import { showNotification } from '@mantine/notifications';
import { UpdateInfoData } from '@/entities/admin-app/user';
import { usersApi } from '@/entities/admin-app/users/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { getProfileSuccessMessage } from '@/lib/utils/getProfileSuccesMessage';
import { useAppSelector } from '../redux/redux';
import { ProfileSuccessMessageEnum } from '@/types/enums/users';
import { t } from 'i18next';
import { isAdmin } from '@/lib/utils/access';

export const useUpdateInfo = () => {
  const [updateInfo, { isSuccess, isLoading, isError }] = useUpdateUserInfoMutation();
  const currentUser = useAppSelector((state) => state.userInfo.User);
  const saveUpdate = useCallback(async ({ Type, Data }: UpdateInfoData) => {
    try {
      const res = await updateInfo({
        Type,
        Data
      });
      if ('error' in res) {
        // ToDo error object has different structure depends on request
        const codeError = extractErrorCode(res.error);
        showNotification({
          title: generateErrorMessage(codeError),
          message: '',
          autoClose: true,
          color: 'red'
        });
      } else {
        clearApiCacheByTags(usersApi, ['Users']);
        showNotification({
          title:
            isAdmin(currentUser) && Type == ProfileSuccessMessageEnum.credentialEmailUpdate
              ? t('saved')
              : getProfileSuccessMessage(Type),
          message: '',
          autoClose: true,
          color: 'green'
        });
      }
    } catch (err: any) {
      console.warn(t('profile.savedMessageError'), err);
    }
  }, []);

  return { saveUpdate, isError, isLoading, isSuccess };
};
