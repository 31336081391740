import React, { useContext } from 'react';
import { PanelsEnum } from '@/types/enums/user';
import { SelectQuotas } from '@/containers/pages/user/components/sections/quotas/select';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { EditUserInfoContext } from '@/containers/pages';
import { CreateUserContext } from '../../../create-user';
import { SimpleGrid } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const Quotas = () => {
  const { t } = useTranslation();
  const { form } = useContext(CreateUserContext);
  const { isLimitedAccess } = useContext(EditUserInfoContext);
  const { Quota, Customer } = form.values;
  const QuotasFormKey = 'Quota';

  return (
    <SectionTemplate title={t('profile.retentionPolicy')} dataSection={PanelsEnum.disk}>
      <SimpleGrid cols={2} w="100%" breakpoints={[{ maxWidth: 'xs', cols: 1 }]}>
        <SelectQuotas
          isLimitedAccess={isLimitedAccess}
          defaultValue={Quota?.Id}
          form={form}
          fieldName={`${QuotasFormKey}.Id`}
          value={Quota?.Id}
          QuotaName={Quota?.Name}
          Customer={Customer?.Id}
          createUser
        />
      </SimpleGrid>
    </SectionTemplate>
  );
};
