import React, { FC, useEffect, useState } from 'react';
import { ActionIcon, Box, Button, Flex, SimpleGrid, Text, TextInput } from '@mantine/core';
import { useStyles } from '../../../styles';
import { SelectUserModal } from '@components/modal/select-user';
import { IconCircleX, IconPlus } from '@tabler/icons';
import { themeColors } from '@/theme/colors';
import { useAppSelector } from '@hooks/redux/redux';
import { useSaveSettings } from '@/containers/pages/filials/hooks/useSaveSettings';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { SettingsEnum } from '@/containers/pages/filials/utils';
import { z } from 'zod';
import { EmailsSelect } from '@/containers/pages/filials/edit/components/tabs/mailing-setup/components/emailsSelect';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

interface ISMTPForm {
  Smtp: {
    User?: { Name: string; Id: number; Count: number } | null;
    Email?: { Name: string; Id: number; Count: number } | null;
  } | null;
}

const noUserScheme = z.object({
  Smtp: z.object({
    User: z.literal(null),
    Email: z
      .object({
        Name: z.string().optional(),
        Id: z.number().optional(),
        Count: z.number().optional()
      })
      .nullable()
  })
});

const withUserScheme = z.object({
  Smtp: z.object({
    User: z.object({ Name: z.string(), Id: z.number(), Count: z.number().optional() }),
    Email: z.object({ Name: z.string().optional(), Id: z.number(), Count: z.number().optional() })
  })
});

const validateScheme = z.union([noUserScheme, withUserScheme]);

const MailingSetupPage: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const { filial } = useAppSelector((state) => state.filials);
  const isSmallScreen = useMediaQuery('(max-width: 576px)');
  const saveSettings = useSaveSettings();

  const form = useForm<ISMTPForm>({
    initialValues: {
      Smtp: {
        User: null,
        Email: null
      }
    },
    validateInputOnChange: true,
    validate: (values): FormErrors => getValidateErrors(validateScheme, values)
  });

  useEffect(() => {
    const { Smtp } = filial?.Settings || {};
    if (!Smtp) {
      return;
    }

    form.setValues({
      Smtp: {
        Email: Smtp.Email ? Smtp.Email : null,
        User: Smtp?.User || null
      }
    });
  }, [filial]);

  const closeModals = () => {
    setIsUserModalOpen(false);
  };

  const handeDeleteItem = () => {
    form.reset();
  };
  const handleSubmit = (formValues: any) => {
    if (!filial) {
      return;
    }
    saveSettings(filial.Id, SettingsEnum.Smtp, {
      Smtp: !formValues.Smtp?.Email?.Id ? null : formValues.Smtp
    });
  };
  const handleConfirmSelectUserModal = async () => {
    if (!selectedUser) {
      return;
    }
    form.setFieldValue('Smtp.User', { Id: selectedUser?.Id, Name: selectedUser.Name });
    form.setFieldValue('Smtp.Email', null);
    closeModals();
  };

  return (
    <Box className={classes.container}>
      <Flex className={classes.section} align="flex-start" direction="column">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Text className={classes.title}>{t('filials.settings.linkSettings')}</Text>
          {form.getInputProps('Smtp.Email').value || form.getInputProps('Smtp.User').value ? (
            <SimpleGrid
              cols={2}
              breakpoints={[{ maxWidth: 'xs', cols: 1 }]}
              className={isSmallScreen ? classes.phoneList : classes.listRow}
            >
              <TextInput
                className={classes.textFieldInput}
                size="sm"
                w="100%"
                mr={32}
                label="Пользователь"
                withAsterisk
                readOnly
                onClick={() => setIsUserModalOpen(true)}
                {...form.getInputProps('Smtp.User.Name')}
              />
              {isSmallScreen && (
                <ActionIcon size={24} mt={0} ml={24} className={classes.phoneIcon}>
                  <IconCircleX color="#667085" onClick={() => handeDeleteItem()} />
                </ActionIcon>
              )}
              <Box display="flex">
                {form.getTransformedValues().Smtp?.User?.Id !== null && (
                  <EmailsSelect
                    form={form}
                    fieldName="Smtp.Email"
                    value={form.getTransformedValues().Smtp?.Email?.Id || null}
                    userId={form.getInputProps('Smtp.User.Id').value}
                  />
                )}
                {!isSmallScreen && (
                  <ActionIcon size={24} mt={32} ml={24} className={classes.removeButton}>
                    <IconCircleX color="#667085" onClick={() => handeDeleteItem()} />
                  </ActionIcon>
                )}
              </Box>
            </SimpleGrid>
          ) : (
            <Button
              className={classes.addButton}
              size="sm"
              variant="outline"
              rightIcon={<IconPlus color={themeColors.brandGray[0]} />}
              onClick={() => setIsUserModalOpen(true)}
              w="max-content"
            >
              <Text>{t('filials.modules.addUser')}</Text>
            </Button>
          )}

          <Text color={themeColors.brandGray[0]} fz="sm" mt={24}>
            {t('filials.settings.linkDescription')}
          </Text>
          <Flex mt="md">
            <Button type="submit" px="32px" radius="md" disabled={!form.isValid()}>
              {t('save')}
            </Button>
          </Flex>
        </form>
      </Flex>
      <SelectUserModal
        multipleUsers={false}
        isOpened={isUserModalOpen}
        title={t('filials.modules.addUser')}
        onClose={closeModals}
        setSelectedUsers={setSelectedUser}
        userId={selectedUser?.Id}
        controlSection
        controlSectionProps={{
          confirmProps: {
            btnName: t('add'),
            fn: handleConfirmSelectUserModal
          },
          cancelProps: {
            btnName: t('cancel'),
            fn: closeModals
          }
        }}
      />
    </Box>
  );
};

export default MailingSetupPage;
