import { t } from 'i18next';
import { ErrorsEnum } from '@/types/enums';
import { pascalToCamel } from '@utils/pascalToCamel';

export const generateErrorMessage = (errorCode: ErrorsEnum): string => {
  if (typeof errorCode === 'number' && errorCode in ErrorsEnum) {
    const camelCaseKey = pascalToCamel(ErrorsEnum[errorCode]);
    return t(`requestsErrors.${camelCaseKey}`);
  } else {
    return `${t(`requestsErrors.error`)} ${errorCode || ''}`;
  }
};
