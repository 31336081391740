import { useCallback, useMemo } from 'react';
import { Avatar, Box, Text, Button, Menu, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import { useAppSelector } from '@hooks/redux/redux';
import { useActions } from '@hooks/redux/action';
import { useLazyLogoutQuery } from '@/entities/admin-app/user/api';
import { getMenu } from '@/components/apps-menu/utils';
import { ModuleSettings, ITokens, IUser } from '@/entities/admin-app/auth';
import { getUserInitials } from '@/lib/utils';
import { MainHeader } from '@/components/header';
import { AppsMenu } from '@/components/apps-menu';
import { UserMenu } from '@/components/user-menu';
import { useTranslation } from 'react-i18next';

export const Dashboard = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSelectedUserId } = useActions();
  const { setUserInfo, setUserTokens } = useActions();
  const [fetchLogout] = useLazyLogoutQuery();
  const { User } = useAppSelector((state) => state.userInfo);
  const userInitials = getUserInitials(User);

  const rightSection = useMemo(
    () =>
      !User ? null : (
        <Group spacing="sm">
          <AppsMenu />
          <UserMenu user={User} />
        </Group>
      ),
    [User]
  );

  const handleClickListItem = useCallback(
    async (event: React.SyntheticEvent<HTMLButtonElement>) => {
      const value = event.currentTarget.dataset.value ?? '';
      switch (value) {
        case 'profile':
          if (User) {
            setSelectedUserId(User?.Id);
            navigate('/profile/' + User?.Id);
          }
          break;
        case 'logout':
          setUserInfo({} as IUser);
          setUserTokens({} as ITokens);
          navigate('/login');
          await fetchLogout();
          break;

        default:
          break;
      }
    },
    [User]
  );

  const modules = useAppSelector(
    (state) => state.userInfo.Settings?.ModuleSettings
  ) as ModuleSettings;
  const menu = modules ? getMenu(modules) : [];

  return (
    <Box className={classes.container}>
      <MainHeader list={[]} rightSection={rightSection} dashboardPage />
      <Box className={classes.root}>
        <Box data-id={User?.Id} className={classes.user_box}>
          <Box className={classes.user_box_avatar}>
            <Avatar
              sx={{ whiteSpace: 'nowrap', zIndex: 1 }}
              size={100}
              src={User?.Avatar}
              radius={50}
              color="blue"
            >
              {userInitials.toUpperCase()}
            </Avatar>
            <Box className="">
              <Text ta="center" weight={400} size={20} mt={16} mb={16}>
                {t('hello')}, {User?.FirstName} {User?.MiddleName}
              </Text>
            </Box>
            <Box className={classes.user_box_buttons}>
              <Button variant="default" data-value="profile" onClick={handleClickListItem}>
                {t('goToProfile')}
              </Button>
              <Button variant="default" data-value="logout" onClick={handleClickListItem}>
                {t('exit')}
              </Button>
            </Box>
          </Box>
          <Box className={classes.menu}>
            {menu.length > 0 && (
              <Menu transitionProps={{ transition: 'fade', duration: 150 }}>
                {menu.map((item, index) => (
                  <Menu.Item
                    className={classes.menu_item}
                    key={index}
                    component="a"
                    href={item.url}
                    fz={14}
                    icon={<Box component="img" src={item.iconSrc} height={48} mt={6} mb={6} />}
                  >
                    <Text weight={400} size={16}>
                      {item?.label}
                    </Text>
                  </Menu.Item>
                ))}
              </Menu>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
