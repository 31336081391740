import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Grid,
  Group,
  Loader,
  Progress,
  Space,
  Text
} from '@mantine/core';
import { Modal } from '@components/modal';
import { Controls } from '@components/modal/components/controls';
import { IRemoveUser } from '@/entities/admin-app/users';
import { useAppSelector } from '@hooks/redux/redux';
import { ListItem } from './list-tem';
import { useStyles } from './styles';
import { TransferData } from './transfer-data';
import { useDeleteUsers } from '@hooks/users/useDeleteUsers';
import { IRowData } from '@components/simple-table/types';
import { useActions } from '@/hooks/redux/action';
import { useTranslation } from 'react-i18next';

interface IDeleteUserProps {
  isOpened: boolean;
  selectedUsers: number[];
  handleClose: () => void;
  setRowData: React.Dispatch<React.SetStateAction<IRowData[]>>;
  scrollRef?: any;
}
export const DeleteUser = ({
  selectedUsers,
  isOpened,
  handleClose,
  setRowData,
  scrollRef
}: IDeleteUserProps) => {
  const { t } = useTranslation();
  const [userList, setUserList] = useState<IRemoveUser[]>([]);
  const [transferUser, setTransferUser] = useState<number | null>(null);
  const { classes } = useStyles();
  const { users } = useAppSelector((state) => state.users);
  const isMounted = useRef(true);
  const { handleDeleteUsers, modalLoadingStatus, percentageComplete } = useDeleteUsers(
    setRowData,
    isMounted,
    scrollRef
  );
  const activeCheckbox = useMemo(() => userList.filter((user) => user.isChecked), [userList]);
  const activeCheckboxLength = activeCheckbox.length;
  const selectedUserLength = selectedUsers?.length;
  const { setSelectedUserId } = useActions();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChange = (user: IRemoveUser) => {
    setUserList((prevUserList) =>
      prevUserList.map((state) => {
        if (state.id === user.id) {
          return user;
        }
        return state;
      })
    );
  };

  const handleDeleteUser = async () => {
    const data = activeCheckbox.map((object) => {
      let transferId: number[] = transferUser ? [transferUser] : [];

      if (object.transferUserId) {
        transferId = [object.transferUserId];
      }

      return {
        UserId: object.id,
        TransferUserId: transferId
      };
    });
    try {
      await handleDeleteUsers(data);
      setSelectedUserId(0);
      handleClose();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSelectedAll = () => {
    setUserList((prevList) => {
      let isChecked = true;
      if (activeCheckboxLength === selectedUserLength) {
        isChecked = false;
      }
      return prevList.map((list) => ({ ...list, isChecked }));
    });
  };

  useEffect(() => {
    const data: IRemoveUser[] = users
      .filter((user) => selectedUsers?.includes(user.Id))
      .map((filtered) => ({
        isChecked: true,
        id: filtered.Id,
        firstName: filtered?.FirstName,
        middleName: filtered?.MiddleName,
        email: filtered?.Email,
        transferUserId: null
      }));

    setUserList(data);
  }, [selectedUsers]);

  const closeModal = (e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    handleClose();
  };

  return (
    <Modal opened={isOpened} size="lg" title={t('users.delete')} onClose={closeModal} withinPortal>
      {modalLoadingStatus && (
        <Box className={classes.box}>
          <Loader />
          {selectedUserLength > 2 && (
            <Progress value={percentageComplete} className={classes.progress} />
          )}
          <Text color="brandGray.0" fz="sm">
            {t('users.deleteTime')}
          </Text>
        </Box>
      )}
      <Container className={modalLoadingStatus ? classes.transparent : ''} px="md" pt={12}>
        {selectedUserLength > 1 ? (
          <>
            <Text fz="sm" mb="sm">
              {t('users.deleteSelected')}
            </Text>
            <Text fz="sm" lh="17px" mb="md">
              {t('users.deleteInfo')}
            </Text>
            <Text color="brandGray.0" fz="xs" mb={12} lh={1}>
              {t('users.chooseTransfer')}
            </Text>
            <TransferData
              userId={-1}
              nameButton={t('users.userTransfer')}
              onChange={setTransferUser}
            />
            <Space h="sm" />
            <ul className={classes.list}>
              <li className={classes.header}>
                <Grid align="flex-end" py={12} m={0}>
                  <Grid.Col p={0} span={7}>
                    <Flex gap="sm">
                      <Checkbox
                        checked={activeCheckboxLength === selectedUserLength}
                        indeterminate={
                          !!activeCheckboxLength && activeCheckboxLength !== selectedUserLength
                        }
                        size="xs"
                        onChange={handleSelectedAll}
                      />
                      <Text color="brandGray.0" fz="xs">
                        {t('profile.firstName')}
                      </Text>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col p={0} span={5}>
                    <Text color="brandGray.0" fz="xs">
                      {t('users.userTransfer')}
                    </Text>
                  </Grid.Col>
                </Grid>
              </li>
              {userList?.map((user) => (
                <li key={user.id}>
                  <ListItem isSelectedMultiple value={user} onChange={handleChange} />
                </li>
              ))}
            </ul>
            <Space h="sm" />
            <Text fz="sm" lh="17px" mb="md">
              {t('users.transferInfo')}
            </Text>
          </>
        ) : (
          <>
            <Text fz="sm" lh="17px" mb="md">
              {t('users.deleteInfo')}
            </Text>
            <ListItem value={userList[0]} onChange={handleChange} />
            <Text fz="sm" lh="17px" mb="md">
              {t('users.transferInfoUser')}
            </Text>
          </>
        )}
      </Container>
      <Controls>
        <Group className={modalLoadingStatus ? classes.transparent : ''} grow>
          <Button variant="default" bg="brandGray.1" onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button
            disabled={!activeCheckboxLength}
            color="red"
            bg="#C23939"
            onClick={handleDeleteUser}
          >
            {t('delete')} {!!activeCheckboxLength && `(${activeCheckboxLength})`}
          </Button>
        </Group>
      </Controls>
    </Modal>
  );
};
