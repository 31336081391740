import { MantineProvider } from '@mantine/core';
import { MainRoutes } from './containers/routes';
import { theme } from './theme/global-theme';
import { Notifications } from '@mantine/notifications';
import AppVersion from '../src/preval';
import { AppFeature } from '@utils/feature';
import { ModalProvider } from './components/modal/modal-provider';
import { useEffect, useState } from 'react';
import { useLazyCurrentUserQuery, useSettingsQuery } from '@/entities/admin-app/user/api';
import { store } from '@/store';
import { userInfoActions } from '@/entities/admin-app/user';
import { IGlobalSettings, IUser } from '@/entities/admin-app/auth';
import { CustomFonts } from './theme/fonts';
import * as Sentry from '@sentry/react';
import { redirectIfAuth } from './utils/authRedirect';

if (process.env.NODE_ENV === 'production' && import.meta.env.VITE_UPLOAD_TO_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://c082040c3b9f33dc9d8fd7fb82dc5032@o489084.ingest.sentry.io/4506344158724096',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/^https:\/\/admin\.r7-space\.ru/]
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

(window as any).appVersion = AppVersion;
(window as any).appFeature = AppFeature;

const App = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { data: dataSetting, status: statusSettings } = useSettingsQuery();
  const [fetchUserInfo, { data: dataUser, status: statusUser }] = useLazyCurrentUserQuery();
  const isAuthorized = Number(dataSetting?.UserId || -1) > 0;

  useEffect(() => {
    if (dataSetting) {
      store.dispatch(userInfoActions.setGlobalSettings(dataSetting as any as IGlobalSettings));
    }
  }, [dataSetting]);

  useEffect(() => {
    if (isAuthorized) {
      redirectIfAuth();
      fetchUserInfo();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (dataUser) {
      store.dispatch(userInfoActions.setUserInfo(dataUser as any as IUser));
    }
  }, [dataUser]);

  useEffect(() => {
    if (
      (isAuthorized && statusUser === 'fulfilled') ||
      statusUser === 'rejected' ||
      (!isAuthorized && statusSettings === 'fulfilled') ||
      statusSettings === 'rejected'
    ) {
      setIsLoading(true);
    }
  }, [statusSettings, statusUser]);

  if (!isLoading) return null;

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <Notifications position="top-right" zIndex={1450} />
      <CustomFonts />
      <ModalProvider>
        <MainRoutes />
      </ModalProvider>
    </MantineProvider>
  );
};

export default App;
