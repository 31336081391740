import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  paginate: {
    backgroundColor: theme.white,
    justifyContent: 'center',
    width: '100%',
    flexWrap: 'nowrap',
    '& .mantine-Pagination-control': {
      border: 'none',
      borderRadius: '0px',
      padding: '23px 28px',
      '&[data-active]': {
        borderBottom: '2px solid #101828',
        backgroundColor: theme.white,
        color: theme.black,
        '&:hover': {
          backgroundColor: theme.white
        }
      },
      '&[data-active]:not([data-disabled]):hover': {
        backgroundColor: theme.white
      }
    },
    padding: '23px 28px'
  },
  simpleNavbar: {
    backgroundColor: theme.colors.backgroundGray[0],
    [theme.fn.smallerThan('md')]: {
      display: 'flex',
      alignItems: 'flex-start',
      marginRight: theme.spacing.xs,
      // marginLeft: theme.spacing.xs,
      backgroundColor: 'white',
      padding: '0',
      height: '100%'
    }
  },

  headerMenu: {
    flexGrow: 1,
    overflow: 'auto',
    [theme.fn.smallerThan('md')]: {
      maxWidth: '850px',
      backgroundColor: 'white',
      borderRadius: '8px',
      margin: '20px 15px'
    },
    [theme.fn.largerThan('md')]: {
      marginLeft: 300
    }
  },

  headerMenuBurger: {
    padding: '20px 0 0 10px',
    button: {
      div: {
        height: '2px'
      }
    },
    [theme.fn.largerThan('md')]: {
      display: 'none'
    }
  },

  section: {
    height: `calc(100vh - 80px)`
  }
}));
