import { useCallback } from 'react';
import { showNotification } from '@mantine/notifications';

import { IFilialCommonForm, IFilialsInfoCommon } from '@/entities/admin-app/filials';

import { useUpdateFilialMutation } from '@/entities/admin-app/filials/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { usersApi } from '@/entities/admin-app/users/api';
import { useTranslation } from 'react-i18next';

export const useSaveFilial = () => {
  const { t } = useTranslation();
  const [updateFilial] = useUpdateFilialMutation();

  return useCallback(async (filial: IFilialsInfoCommon | null, values: IFilialCommonForm) => {
    try {
      const res = await updateFilial({
        ...values,
        Title: values.Title.trim(),
        Id: filial?.Id
      });
      if ('error' in res) {
        showNotification({
          title: `${(res as any)?.error?.data}`,
          message: '',
          autoClose: true,
          color: 'red'
        });
      } else {
        showNotification({
          title: t('filials.successSave'),
          message: '',
          autoClose: true,
          color: 'green'
        });
        clearApiCacheByTags(usersApi, ['Users']);
      }
    } catch (err: any) {
      console.warn(t('filials.errors.save'), err);
    }
  }, []);
};
