import { createStyles } from '@mantine/core';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme) => ({
  modalRoot: modalTheme(theme).root,
  modalContainer: modalTheme(theme).container,
  modalFooter: modalTheme(theme).footer,
  modalCancelBtn: modalTheme(theme).cancelBtn,
  addBtn: {
    borderRadius: '8px',
    border: `1px solid ${theme.colors.brandGray[0]}`,
    marginBottom: 'auto',
    '&:hover': {
      backgroundColor: '#f6f7f8'
    },

    '& .mantine-Button-inner': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    '& .mantine-Button-label': {
      color: theme.black
    }
  },
  changePasswordButton: {
    backgroundColor: theme.colors.backgroundGray[6],
    borderRadius: '8px',
    border: `1px solid ${theme.colors.backgroundGray[6]}`,
    marginBottom: 'auto',
    '&:hover': {
      backgroundColor: '#f6f7f8'
    },

    '& .mantine-Button-label': {
      color: theme.black
    },
    marginTop: '26px!important' as any
  },

  listRow: {
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr 60px',
    width: '100%',

    '& .mantine-TextInput-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    },
    '@media (max-width: 576px)': {
      width: '90%'
    }
  },
  badge: {
    paddingRight: 3,
    backgroundColor: theme.colors.backgroundGray[6],
    color: 'black',
    borderRadius: '16px',
    marginBottom: '4px',
    border: 'none',
    textTransform: 'inherit',
    // height: '28px',
    fontSize: '14px',
    padding: theme.spacing.sm,
    fontWeight: 400,
    marginTop: '24px',
    height: '2.25rem'
  },

  removeButton: {
    marginBottom: '2px',
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& .mantine-ActionIcon-root': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      alignSelf: 'center'
    },
    '& button:hover': {
      backgroundColor: 'transparent'
    },
    [theme.fn.smallerThan('xs')]: {
      position: 'absolute',
      right: '10px',
      top: '40%'
    }
  },

  title: {
    fontWeight: 600
  },

  textFieldInput: {
    '& .mantine-TextInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    },

    '& .mantine-TextInput-input': {
      borderRadius: '4px',
      border: '1px solid #D0D5DD'
    }
  },

  createContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between'
  },

  controlsButtons: {
    justifyContent: 'space-between',
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
      'button:first-of-type': {
        margin: '0 0 15px 0'
      }
    }
  }
}));
