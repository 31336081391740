import React, { FC, useContext } from 'react';
import { ActionIcon, Box } from '@mantine/core';
import { UserInfoMainContext } from '../../panels';
import { IconArrowLeft } from '@tabler/icons';
import { Action } from '@/containers/pages/users/components/table/action';
import { useActions } from '@hooks/redux/action';
export const DrawerHeader: FC = () => {
  const { setRowData, userInfoCommon } = useContext(UserInfoMainContext);
  const loginAs = userInfoCommon?.Credentials?.Login;

  const { setSelectedUserId } = useActions();

  const goBack = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedUserId(0);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <ActionIcon onClick={goBack} size="md">
        <IconArrowLeft color="#667085" />
      </ActionIcon>
      <Action
        loginAsUser={loginAs}
        hasIconEye={false}
        setRowData={setRowData}
        item={{
          ...userInfoCommon,
          Id: userInfoCommon?.Id,
          Email: userInfoCommon?.Credentials?.Email
        }}
      />
    </Box>
  );
};
