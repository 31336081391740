import { useNavigate } from 'react-router-dom';
import { Menu, Group, Avatar, Text, Divider, Stack } from '@mantine/core';
import { IconUserCircle, IconLogout } from '@tabler/icons';
import { useActions } from '@hooks/redux/action';
import { ITokens, IUser } from '@/entities/admin-app/auth';
import { useLazyLogoutQuery } from '@/entities/admin-app/user/api';
import { getUserInitials } from '@/lib/utils';
import { useStyles } from './styles';
import { useMediaQuery } from '@mantine/hooks';
import { HomeIcon } from '@/assets/icons';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

interface Props {
  user: IUser;
}

export const UserMenu = ({ user }: Props) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setUserInfo, setUserTokens } = useActions();

  const [fetchLogout] = useLazyLogoutQuery();

  const userInitials = getUserInitials(user);

  const handleProfile = () => navigate(`/profile/${user.Id}`);
  const handleDashboard = () => navigate(ROUTES.dashboard.fullPath);
  const isMobile = useMediaQuery('(max-width: 576px)');

  const handleLogout = async () => {
    setUserInfo({} as IUser);
    setUserTokens({} as ITokens);
    navigate('/login');
    await fetchLogout();
  };

  const avatarSrc = useMemo(() => {
    return user?.HasAvatar
      ? `/api/v2/profile/getAvatar?id=${user.Id}${user.AvatarKey ? `&key=${user.AvatarKey}` : ''}`
      : '';
  }, [user.AvatarKey, user.HasAvatar]);

  return (
    <Menu
      position="bottom-end"
      shadow="md"
      transitionProps={{ transition: 'scale-y', duration: 150 }}
      classNames={{ dropdown: classes.dropdown }}
    >
      <Menu.Target>
        <Avatar
          style={{ cursor: 'pointer' }}
          src={avatarSrc}
          size={isMobile ? 35 : 42}
          radius="xl"
          color="blue"
          alt={user.Name}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {userInitials}
        </Avatar>
      </Menu.Target>
      <Menu.Dropdown miw={320} maw={400}>
        <Group px="sm" pt={10} spacing={10}>
          <Avatar
            src={user?.HasAvatar ? `/api/v2/profile/getAvatar?id=${user.Id}` : null}
            size={50}
            radius="xl"
            color="blue"
            alt={user.Name}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {userInitials}
          </Avatar>
          <Stack spacing={0}>
            <Text fz={18} className={classes.truncate}>
              {user.Surname ?? '-'} {user.FirstName ?? '-'}
            </Text>
            <Text fz={14} c="dimmed">
              {user.Email}
            </Text>
          </Stack>
        </Group>
        <Divider mt={12} mb={12} />
        <Menu.Item icon={<HomeIcon />} px="sm" onClick={handleDashboard}>
          {t('home')}
        </Menu.Item>
        <Menu.Item icon={<IconUserCircle stroke={1.5} size={20} />} px="sm" onClick={handleProfile}>
          {t('profil')}
        </Menu.Item>
        <Menu.Item onClick={handleLogout} icon={<IconLogout stroke={1.5} size={20} />} px="sm">
          {t('exit')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
