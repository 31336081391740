import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@/utils/customBaseQuery';

import { IFullResponse } from '@/types/api';
import { BASE_URL } from '../constants';
import { IGlobalSettings, IUserData } from '@/entities/admin-app/auth';
import {
  ICloseseSsionsPayload,
  IQueryUserChangePassword,
  IQueryUserResetPassword,
  IQueryUserChangeRoles,
  IQueryUserChangeStatuses,
  IUserDeleteRole,
  IUserInfoCommon,
  IUserInfo,
  UpdateInfoData
} from './types';
import { IQueryBulkAddUsers, IQueryChangeFilial } from '@/entities/admin-app/groups';

export const userApi = createApi({
  reducerPath: 'api/user',
  baseQuery: customBaseQuery(`${BASE_URL}`),
  refetchOnFocus: false,
  tagTypes: ['UserInfo'],
  endpoints: (build) => ({
    currentUser: build.query<IUserData, void>({
      query: () => ({
        url: 'v1/user'
      })
    }),
    settings: build.query<IGlobalSettings, void>({
      query: () => ({
        url: 'v1/settings'
      })
    }),
    add: build.mutation<IUserInfo, IUserInfo>({
      query: (payload: IUserInfo) => ({
        url: `v2/userprofile`,
        body: {
          Data: payload,
          Type: 1
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IUserInfo>) => response.Response.Data
    }),
    changeStatuses: build.mutation<void, IQueryUserChangeStatuses>({
      query: (payload?: IQueryUserChangeStatuses) => ({
        url: `v1/User/ChangeStatuses`,
        body: {
          ...payload
        },
        method: 'post'
      }),
      invalidatesTags: (result, error, arg) => [
        ...arg.Ids.map((id) => ({ type: 'UserInfo' as const, id: id }))
      ]
    }),
    changeRoles: build.mutation<void, IQueryUserChangeRoles>({
      query: (payload?: IQueryUserChangeRoles) => ({
        url: `v1/User/BulkChangeRoles`,
        body: {
          ...payload
        },
        method: 'post'
      })
    }),
    bulkAddRoles: build.mutation<void, IQueryBulkAddUsers>({
      query: (payload: IQueryBulkAddUsers) => ({
        url: `v1/User/BulkAddRoles`,
        body: payload,
        method: 'post'
      }),
      invalidatesTags: (result, error, arg) => [
        ...arg.UserIds.map((id) => ({ type: 'UserInfo' as const, id: id }))
      ]
    }),
    bulkChangeFilial: build.mutation<void, IQueryChangeFilial>({
      query: (payload: IQueryChangeFilial) => ({
        url: `v1/User/changecustomer`,
        body: payload,
        method: 'post'
      }),
      invalidatesTags: (result, error, arg) => [
        ...arg.UserIds.map((id) => ({ type: 'UserInfo' as const, id: id }))
      ]
    }),
    deleteAvatar: build.mutation<void, number>({
      query: (id: number) => ({
        url: `v2/Profile/DeleteAvatar?UserId=${id}`,
        method: 'delete'
      })
    }),
    getUserInfoCommon: build.query<IUserInfoCommon, number>({
      query: (id: number) => ({
        url: `v2/UserInfo/Common?UserId=${id}`
      }),
      providesTags: (response, error, arg) =>
        response ? [{ type: 'UserInfo' as const, id: arg }] : ['UserInfo'],
      transformResponse: (response: IFullResponse<IUserInfoCommon>) => response.Response.Data
    }),
    closeseSsions: build.mutation<void, ICloseseSsionsPayload>({
      query: (payload: ICloseseSsionsPayload) => ({
        url: `v2/auth/CloseseSsions`,
        body: payload,
        method: 'post'
      }),
      invalidatesTags: ['UserInfo']
    }),
    logout: build.query<void, void>({
      query: () => ({
        url: `v2/auth/Logout`
      })
    }),
    changePassword: build.mutation<void, IQueryUserChangePassword>({
      query: (payload: IQueryUserChangePassword) => ({
        url: `v2/auth/ChangePassword`,
        body: payload,
        method: 'post'
      })
    }),
    resetPassword: build.mutation<void, IQueryUserResetPassword>({
      query: (payload: IQueryUserResetPassword) => ({
        url: 'v2/Auth/loginresetpassword',
        body: payload,
        method: 'post'
      })
    }),
    updateUser: build.mutation<void, IUserInfoCommon>({
      query: (payload: IUserInfoCommon) => ({
        url: `v2/UserInfo/Update`,
        body: payload,
        method: 'post'
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'UserInfo' as const, id: arg.UserId }]
    }),
    deleteRole: build.mutation<void, IUserDeleteRole>({
      query: (payload: IUserDeleteRole) => ({
        url: `v1/user/removerole?userid=${payload.UserId}&roleid=${payload.RoleId}`,
        method: 'delete'
      })
    }),
    getUserInfo: build.query<IUserInfo, number>({
      query: (id: number) => ({
        url: `v2/userprofile?id=${id}`,
        method: 'get'
      }),
      providesTags: (response, error, arg) =>
        response ? [{ type: 'UserInfo' as const, id: arg }] : ['UserInfo'],
      transformResponse: (response: IFullResponse<IUserInfo>) => response.Response.Data
    }),
    updateUserInfo: build.mutation<IUserInfo, UpdateInfoData>({
      query: (payload: UpdateInfoData) => ({
        url: `v2/userprofile`,
        body: payload,
        method: 'post'
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'UserInfo' as const, id: arg.Data.Id }]
    })
  })
});

export const {
  useCurrentUserQuery,
  useSettingsQuery,
  useLazySettingsQuery,
  useLazyCurrentUserQuery,
  useChangeStatusesMutation,
  useChangeRolesMutation,
  useBulkChangeFilialMutation,
  useBulkAddRolesMutation,
  useDeleteAvatarMutation,
  useGetUserInfoCommonQuery,
  useCloseseSsionsMutation,
  useLazyLogoutQuery,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useUpdateUserMutation,
  useAddMutation,
  useDeleteRoleMutation,
  useGetUserInfoQuery,
  useUpdateUserInfoMutation,
  useLazyGetUserInfoQuery
} = userApi;
