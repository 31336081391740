import React, { useEffect, useState } from 'react';
import { useLazyResetPasswordQuery } from '@/entities/admin-app/auth/api';
import { Box, Button, Loader, SimpleGrid, TextInput, UnstyledButton, Text } from '@mantine/core';
import { IconCopy } from '@tabler/icons';
import { Modal } from '@/components/modal';
import { useStyles } from './styles';
import { copyToClipboard } from '@/containers/pages/user/utils';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';

interface IResetPassword {
  defaultLink?: string;
  currentId: number;
}
export const ResetPasswordSection = ({ defaultLink, currentId }: IResetPassword) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [resetLink, setResetLink] = useState<string | undefined>(defaultLink);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState<boolean>(false);

  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const [handleResetPassword, { data, isLoading, isSuccess }] = useLazyResetPasswordQuery();
  const isMobile = useMediaQuery('(max-width: 576px)');

  const copyText = async () => {
    try {
      copyToClipboard(resetLink);
      setLinkCopied(true);
    } catch (e) {
      setLinkCopied(false);
    }
  };

  const handleReset = () => {
    handleResetPassword({ UserId: currentId });
    setOpenResetPasswordModal(false);
    setLinkCopied(false);
  };

  useEffect(() => {
    if (data) {
      setResetLink(data?.Url);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (defaultLink) {
      setResetLink(defaultLink);
    }
  }, [defaultLink]);

  const closeModal = (e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    setOpenResetPasswordModal(false);
  };

  return (
    <>
      <SimpleGrid cols={isMobile ? 1 : 2} w="100%" className={classes.container}>
        <TextInput
          className={classes.linkInput}
          label={t('profile.passwordRecoveryLink')}
          placeholder={t('profile.noActiveLink')}
          rightSection={
            resetLink && <IconCopy color={linkCopied ? 'teal' : 'blue'} onClick={copyText} />
          }
          value={resetLink}
          readOnly={true}
        />
        <UnstyledButton
          onClick={() => setOpenResetPasswordModal(true)}
          className={classes.resetPasswordButton}
        >
          {isLoading ? (
            <Box>
              <Loader size="sm" />
            </Box>
          ) : (
            t('profile.resetPassword')
          )}
        </UnstyledButton>
      </SimpleGrid>
      <Modal
        zIndex={1400}
        size="400px"
        opened={openResetPasswordModal}
        onClose={() => setOpenResetPasswordModal(false)}
        title={t('profile.passwordReset')}
      >
        <Box className={classes.modalRoot}>
          <Text className={classes.modalContainer} py="md">
            {t('profile.resetPasswordAndGenerateLink')}
          </Text>
          <Box className={classes.modalFooter}>
            <Button size="sm" radius={8} w="49%" onClick={handleReset}>
              {t('profile.reset')}
            </Button>
            <UnstyledButton className={classes.modalCancelBtn} w="49%" onClick={closeModal}>
              {t('profile.cancel')}
            </UnstyledButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
