import { Box, Burger, LoadingOverlay } from '@mantine/core';
import { ErrorBoundary } from '@components/error-boundary';
import { SimpleNavbar } from '@components/simple-navbar';
import { useStyles } from './styles';
import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '@hooks/redux/redux';
import { Outlet } from 'react-router-dom';
import { isSuperAdmin } from '@/lib/utils/access';
import { NavMenu } from './components/nav-menu';
import { NotFound } from '@/components/not-found';
import { Layout } from '@/containers/layout';
import { useCommonSettingsQuery } from '@/entities/admin-app/commonSettings/api';
import { useActions } from '@hooks/redux/action';
const CommonSettingsPage: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const currentUser = useAppSelector((state) => state.userInfo.User);
  const isLimitedAccess = !isSuperAdmin(currentUser);
  const { data, isLoading } = useCommonSettingsQuery();
  const { setCommonSettings } = useActions();

  // useEffect(() => {
  //   if (error && (error as any)?.data?.ErrorCode) {
  //     const codeError = extractErrorCode(error);
  //     const nativeErrorCode = (error as any)?.data?.ErrorCode;
  //     showNotification({
  //       title:
  //         nativeErrorCode === 406 || codeError === ErrorsEnum.NoRights
  //           ? t('requestsErrors.NoRights')
  //           : '',
  //       message: '',
  //       autoClose: true,
  //       color: 'red',
  //       style: { zIndex: 1003 }
  //     });
  //   }
  // }, [error]);

  useEffect(() => {
    if (data) {
      setCommonSettings(data);
    }
  }, [data]);

  return (
    <ErrorBoundary componentTitle="Settings page">
      {/*<EditUserInfoContext.Provider value={initialValues}>*/}
      {isLimitedAccess ? (
        <NotFound />
      ) : (
        <Layout
          // rightNavbarContent={<div className={classes.fakeNavbar}></div>}
          leftNavbarContent={
            <SimpleNavbar
              widthNav={290}
              style={{ flexShrink: 0, borderRight: 'none' }}
              className={classes.simpleNavbar}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            >
              <NavMenu setIsOpen={setIsOpen} />
            </SimpleNavbar>
          }
        >
          <Box className={classes.container} ref={containerRef}>
            <Box className={classes.headerMenu}>
              <Burger
                opened={isOpen}
                onClick={() => setIsOpen(true)}
                className={classes.headerMenuBurger}
                size="sm"
              />
            </Box>
            <Outlet />
          </Box>

          <Box className={classes.section}>{children}</Box>
          <LoadingOverlay visible={isLoading} overlayBlur={4} />
        </Layout>
      )}
    </ErrorBoundary>
  );
};

export default CommonSettingsPage;
