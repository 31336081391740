import { useContext } from 'react';
import { Box } from '@mantine/core';
import moment from 'moment';

import { TextField } from '@components/fields/text-field';
import { TextFieldTags } from '@components/fields/text-field-tags';

import { FilialsInfoMainContext } from '../../panels';

import { formatDateTime } from '@/lib/utils/date';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';

export const BaseInfo = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { filialsInfoCommon } = useContext(FilialsInfoMainContext);
  const { Settings } = useAppSelector((state) => state.userInfo);

  return (
    <Box className={classes.content}>
      {isSaaS(Settings) ? null : (
        <>
          <TextField
            title={t('filials.settings.filialCode')}
            description={filialsInfoCommon?.Company}
          />
          <TextField
            title={t('filials.settings.filialParent')}
            description={filialsInfoCommon?.Parent?.Name}
          />
          <TextFieldTags
            title={t('filials.settings.filialKids')}
            tags={filialsInfoCommon?.Children}
          />
        </>
      )}

      <TextFieldTags title={t('profile.roles')} tags={filialsInfoCommon?.Roles} />
      <TextField
        title={t('dateOfCreation')}
        description={formatDateTime(moment.utc(filialsInfoCommon?.DateOfCreate))}
      />
    </Box>
  );
};
