import {
  Button,
  Box,
  UnstyledButton,
  TextInput,
  SimpleGrid,
  NumberInput,
  Flex,
  LoadingOverlay,
  Checkbox,
  Textarea,
  ScrollArea,
  Switch
} from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { Modal } from '@/components/modal';
import { useStyles } from '../../../../styles';
import React, { useEffect, useState } from 'react';
import { useLazyUpdateEmailServerQuery } from '@/entities/admin-app/email/api';
import { z } from 'zod';
import { emailServerConnectionType } from '@/containers/pages/filials/edit/utiils';
import { getValidateErrors } from '@/lib/utils/validation';
import { ConnectionsSelect } from '@/containers/pages/filials/edit/components/tabs/mail-servers/components/connection-select';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import i18n from '@/entities/i18next';

interface IMailServer {
  Integration: {
    Ssh: {
      Server: string;
      User: string;
      Port: number;
      PrivateKeyBase64: string;
      HasKey: boolean;
    };
  } | null;
  ImapServer: string;
  ImapPort: number | null;
  SmtpServer: string;
  SmtpPort: number | null;
  PopServer: string;
  PopPort: number | null;
  PopConnectionType: emailServerConnectionType;
  SmtpConnectionType: emailServerConnectionType;
  ImapConnectionType: emailServerConnectionType;
  CustomerId: number;
  Json: string | null;
  Name: string;
  Domains: string;
  Id?: number;
  useSsH: boolean;
}

const portSchema = z
  .number({
    required_error: 'Введите порт',
    invalid_type_error: 'Порт должен быть числом'
  })
  .positive({ message: 'Должен быть положительным' })
  .lt(65536, { message: 'Должен быть меньше 65536' });

const basicSchema = z.object({
  Name: z
    .string()
    .refine((value) => value.trim().length > 0, {
      message: i18n.t('validation.emptyInput')
    })
    .refine((value) => value.length > 0, {
      message: i18n.t('errors.enterName')
    }),
  Domains: z
    .string({ invalid_type_error: 'Введите  домены' })
    .refine((value) => value.trim().length > 0, {
      message: i18n.t('validation.emptyInput')
    })
    .refine((value) => value.length > 0, {
      message: i18n.t('validation.required')
    }),
  PopServer: z
    .string()
    .refine((value) => value.trim().length > 0, {
      message: i18n.t('validation.emptyInput')
    })
    .refine((value) => value.length > 0, {
      message: i18n.t('errors.enterPop')
    }),
  PopPort: portSchema,
  PopConnectionType: z.nativeEnum(emailServerConnectionType),
  ImapServer: z
    .string()
    .refine((value) => value.trim().length > 0, {
      message: i18n.t('validation.emptyInput')
    })
    .refine((value) => value.length > 0, {
      message: i18n.t('errors.enterImap')
    }),
  ImapPort: portSchema,
  ImapConnectionType: z.nativeEnum(emailServerConnectionType),
  SmtpServer: z
    .string()
    .refine((value) => value.trim().length > 0, {
      message: i18n.t('validation.emptyInput')
    })
    .refine((value) => value.length > 0, {
      message: i18n.t('errors.enterSmtp')
    }),
  SmtpPort: portSchema,
  SmtpConnectionType: z.nativeEnum(emailServerConnectionType),
  Json: z.string().nullable(),
  CustomerId: z.number().nullable().optional()
});

interface ICreateMailServerProps {
  CustomerId: number;
  handleChange?: () => void;
  mailServer?: IMailServer;
  opened: boolean;
  handleClose: () => void;
  isEditing: boolean;
}

export const MailServerForm = ({
  CustomerId,
  handleChange,
  mailServer,
  opened,
  handleClose,
  isEditing
}: ICreateMailServerProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [createMailServer, { isFetching, isError }] = useLazyUpdateEmailServerQuery();
  const [showKey, setShowKey] = useState(false);

  const noSSHSchema = basicSchema.extend({
    useSsH: z.literal(false),

    Integration: z
      .object({
        Ssh: z.object({})
      })
      .nullable()
  });

  const SSHSchema = basicSchema.extend({
    useSsH: z.literal(true),
    Integration: z.object({
      Ssh: z.object({
        Server: z
          .string()
          .refine((value) => value.trim().length > 0, {
            message: i18n.t('validation.emptyInput')
          })
          .refine((value) => value.length > 0, {
            message: i18n.t('servers.addSshServer')
          }),
        User: z
          .string()
          .refine((value) => value.trim().length > 0, {
            message: i18n.t('validation.emptyInput')
          })
          .refine((value) => value.length > 0, {
            message: i18n.t('servers.addLogin')
          }),
        Port: portSchema,
        PrivateKeyBase64: z.string().refine(
          (val) => {
            return showKey ? val.length > 1 : true;
          },
          { message: t('servers.addKey') }
        )
      })
    })
  });

  const validateScheme = z.union([noSSHSchema, SSHSchema]);

  const form = useForm<IMailServer>({
    initialValues: {
      Integration: null,
      ImapServer: '',
      ImapPort: 0,
      SmtpServer: '',
      SmtpPort: 0,
      PopServer: '',
      PopPort: 0,
      PopConnectionType: 1,
      SmtpConnectionType: 1,
      ImapConnectionType: 1,
      CustomerId: CustomerId,
      Json: null,
      Name: '',
      Domains: '',
      useSsH: false
    },
    validateInputOnChange: true,
    validate: (values: IMailServer): FormErrors => getValidateErrors(validateScheme, values)
  });

  const errors = form.errors;
  const isDirtyForm = form.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  useEffect(() => {
    if (CustomerId) {
      form.setFieldValue('CustomerId', CustomerId);
    }
  }, [CustomerId]);

  useEffect(() => {
    if (mailServer) {
      const value = {
        ...JSON.parse(JSON.stringify(mailServer)),
        useSsH: !!mailServer.Integration
      };
      form.setValues(value);
      form.resetDirty(value);
    }
  }, [mailServer]);

  const modalClose = () => {
    handleClose();
    form.reset();
  };

  const handleSubmit = async (values: any) => {
    const converted = { ...values, Integration: values.useSsH ? values.Integration : null };
    delete converted['useSsH'];

    try {
      const res = await createMailServer({
        ...converted
        // Integration: values.useSsH ? values.Integration : null
      });
      if ('error' in res) {
        showNotification({
          title: `${(res as any)?.error?.data}`,
          message: '',
          autoClose: true,
          color: 'red',
          style: { zIndex: 1003 }
        });
      } else {
        showNotification({
          title: t('servers.createSuccess'),
          message: '',
          autoClose: true,
          color: 'green',
          style: { zIndex: 1003 }
        });
        handleClose();
        if (handleChange) {
          handleChange();
        }
      }
    } catch (err: any) {
      console.warn(t('servers.createError'), err);
    }
  };

  const handleAddChangeSSH = () => {
    if (!form.getTransformedValues().useSsH) {
      form.setFieldValue('Integration', {
        Ssh: {
          User: '',
          Port: 0,
          PrivateKeyBase64: '',
          Server: '',
          HasKey: false
        }
      });
      setShowKey(true);
    } else {
      form.setFieldValue('Integration', null);
    }
    form.setFieldValue('useSsH', !form.getTransformedValues().useSsH);
  };

  const isMobile = useMediaQuery('(max-width: 992px)');
  const isTablet = useMediaQuery('(max-width: 1200px)');

  useEffect(() => {
    if (form.getTransformedValues().useSsH === true && !showKey) {
      form?.clearFieldError('Integration.Ssh.PrivateKeyBase64');
      form?.setFieldValue('Integration.Ssh.PrivateKeyBase64', '');
    }
  }, [showKey]);

  useEffect(() => {
    const integrationErrorKeys = Object.keys(form.errors).filter((key) =>
      key.includes('Integration')
    );
    if (form.getTransformedValues().useSsH === false && integrationErrorKeys.length > 0) {
      integrationErrorKeys.forEach((key) => {
        form.clearFieldError(key);
      });
    }
  }, [form.values.Integration]);

  return (
    <Modal
      opened={opened}
      onClose={modalClose}
      title={isEditing ? t('servers.edit') : t('servers.add')}
      zIndex={1300}
      scrollAreaComponent={ScrollArea.Autosize}
      size={isTablet ? '90%' : '60%'}
    >
      <form className={classes.modalRoot} onSubmit={form.onSubmit(handleSubmit)}>
        <Box className={classes.modalContainer} sx={{ paddingBottom: '100px' }}>
          <SimpleGrid cols={isMobile ? 1 : 2} w="100%">
            <TextInput
              className={classes.textFieldInput}
              placeholder={t('servers.name')}
              label={t('servers.nameLabel')}
              withAsterisk
              {...form.getInputProps('Name')}
            />
            <TextInput
              className={classes.textFieldInput}
              placeholder={t('servers.domains')}
              label={t('servers.domainsLabel')}
              withAsterisk
              {...form.getInputProps('Domains')}
            />
          </SimpleGrid>
          <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
            <TextInput
              className={classes.textFieldInput}
              placeholder={t('servers.popServer')}
              label={t('servers.popServer')}
              withAsterisk
              {...form.getInputProps('PopServer')}
            />
            <Flex
              className={classes.rightSideContainer}
              gap="24px"
              direction={isMobile ? 'column' : 'row'}
            >
              <NumberInput
                className={classes.textFieldInput}
                placeholder={t('servers.port')}
                label={t('servers.port')}
                autoComplete="off"
                withAsterisk
                hideControls
                maw={isMobile ? 'auto' : 70}
                mb={isMobile ? '20px' : ''}
                defaultValue=""
                {...form.getInputProps('PopPort')}
              />
              <ConnectionsSelect
                placeholder={t('servers.connection')}
                form={form}
                fieldName="PopConnectionType"
                label={t('servers.connectionType')}
                value={form.getInputProps('PopConnectionType').value}
              />
            </Flex>
          </SimpleGrid>
          <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
            <TextInput
              className={classes.textFieldInput}
              placeholder={t('servers.imapServer')}
              label={t('servers.imapServer')}
              withAsterisk
              {...form.getInputProps('ImapServer')}
            />
            <Flex
              className={classes.rightSideContainer}
              gap="24px"
              direction={isMobile ? 'column' : 'row'}
            >
              <NumberInput
                className={classes.textFieldInput}
                autoComplete="off"
                maw={isMobile ? 'auto' : 70}
                placeholder={t('servers.port')}
                label={t('servers.port')}
                withAsterisk
                hideControls
                defaultValue=""
                mb={isMobile ? '20px' : ''}
                {...form.getInputProps('ImapPort')}
              />
              <ConnectionsSelect
                placeholder={t('servers.connection')}
                form={form}
                fieldName="ImapConnectionType"
                label={t('servers.connectionType')}
                value={form.getInputProps('ImapConnectionType').value}
              />
            </Flex>
          </SimpleGrid>
          <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
            <TextInput
              className={classes.textFieldInput}
              placeholder={t('servers.smtpServer')}
              label={t('servers.smtpServer')}
              withAsterisk
              {...form.getInputProps('SmtpServer')}
            />
            <Flex
              className={classes.rightSideContainer}
              gap="24px"
              direction={isMobile ? 'column' : 'row'}
            >
              <NumberInput
                className={classes.textFieldInput}
                autoComplete="off"
                maw={isMobile ? 'auto' : 70}
                placeholder={t('servers.port')}
                label={t('servers.port')}
                withAsterisk
                hideControls
                defaultValue=""
                mb={isMobile ? '20px' : ''}
                {...form.getInputProps('SmtpPort')}
              />

              <ConnectionsSelect
                placeholder={t('servers.connection')}
                form={form}
                fieldName="SmtpConnectionType"
                label={t('servers.connectionType')}
                value={form.getInputProps('SmtpConnectionType').value}
              />
            </Flex>
          </SimpleGrid>
          <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
            <Checkbox
              className={classes.checkbox}
              label={t('servers.sshIntegration')}
              size="xs"
              {...form.getInputProps('useSsH', { type: 'checkbox' })}
              onChange={() => handleAddChangeSSH()}
            />
          </SimpleGrid>
          {form.getTransformedValues().useSsH === true && (
            <>
              <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
                <TextInput
                  className={classes.textFieldInput}
                  placeholder={t('servers.server')}
                  label={t('servers.server')}
                  withAsterisk
                  {...form.getInputProps('Integration.Ssh.Server')}
                />

                <Flex
                  className={classes.rightSideContainer}
                  gap="24px"
                  direction={isMobile ? 'column' : 'row'}
                >
                  <NumberInput
                    className={classes.textFieldInput}
                    autoComplete="off"
                    maw={isMobile ? 'auto' : 70}
                    placeholder={t('servers.port')}
                    label={t('servers.port')}
                    withAsterisk
                    hideControls
                    defaultValue=""
                    mb={isMobile ? '20px' : ''}
                    {...form.getInputProps('Integration.Ssh.Port')}
                  />
                  <TextInput
                    w="100%"
                    className={classes.textFieldInput}
                    placeholder="Login"
                    label="Login"
                    withAsterisk
                    {...form.getInputProps('Integration.Ssh.User')}
                  />
                </Flex>
              </SimpleGrid>
              <SimpleGrid cols={1} mt={16} w="100%">
                {form.values.Integration?.Ssh.HasKey ? (
                  <Switch
                    checked={showKey}
                    size="xs"
                    onChange={(event) => setShowKey(event.currentTarget.checked)}
                    label="Изменить ключ"
                  />
                ) : null}
                {showKey ? (
                  <Textarea
                    withAsterisk
                    className={classes.textFieldInput}
                    label={t('servers.key')}
                    size="xs"
                    minRows={3}
                    {...form.getInputProps('Integration.Ssh.PrivateKeyBase64')}
                  />
                ) : null}
              </SimpleGrid>
            </>
          )}
        </Box>
        <Box
          className={classes.modalFooter}
          sx={{
            position: 'fixed',
            bottom: '0',
            zIndex: 1,
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px'
          }}
        >
          <Button
            type="submit"
            mb="0"
            mr={isMobile ? 0 : 24}
            w={isMobile ? '100%' : '49%'}
            disabled={isDisabled}
          >
            {isEditing ? t('save') : t('add')}
          </Button>
          <UnstyledButton
            ml={8}
            className={classes.modalCancelBtn}
            w={isMobile ? '100%' : '49%'}
            onClick={handleClose}
          >
            Отмена
          </UnstyledButton>
        </Box>
        <LoadingOverlay visible={isFetching && !isError} overlayBlur={2} />
      </form>
    </Modal>
  );
};
