import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  badge: {
    paddingRight: 3,
    backgroundColor: theme.colors.backgroundGray[5],
    color: 'black',
    borderRadius: '16px',
    marginRight: theme.spacing.xs,
    marginTop: theme.spacing.xs,
    border: 'none',
    textTransform: 'inherit',
    height: '28px',
    fontSize: '14px',
    padding: theme.spacing.xs,
    fontWeight: 400
  }
}));
