import { FC, useEffect, useState } from 'react';
import { Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { GenderEnum } from '../../../types/enums';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface IDepartmentProps {
  defaultValue?: string;
  form: UseFormReturnType<any>;
  fieldName?: string;
  clearable?: boolean;
}

const list = [
  { label: 'Мужской', value: String(GenderEnum.male) },
  { label: 'Женский', value: String(GenderEnum.female) }
];

export const Gender: FC<IDepartmentProps> = ({ form, fieldName = 'Gender', clearable = false }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(Math.random());
  }, [form.values[fieldName]]);

  return (
    <Select
      key={key}
      className={classes.gender}
      clearable={clearable}
      data={list}
      label={t('profile.gender')}
      placeholder={t('profile.choose')}
      value={String(form.getInputProps(fieldName).value)}
      onChange={(query: string) => {
        form?.setFieldValue(fieldName, Number(query));
      }}
      error={form.errors[fieldName]}
      //rightSection={<IconChevronDown color="#98A2B3" />}
    />
  );
};
