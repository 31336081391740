import { IPasswordSecurity } from '@/entities/admin-app/filials';
import { IGeneratePasswordRegExp } from '@/types';
export const allowedPasswordCharactersString = '~ @ # % ^ * _ + = - ? : {  } [ ]  \\ /';
export const allPasswordCharacters = `$&+,:;=?@#|'<>.^*()%!-`;

export const allowedKeyboardSpecialCharacters = '~@#%^*_+=-?:{}[]/';
export const keyboardSpecialCharacters = '!@#$%^&*()_+[]{}|;:\'",.<>?/';
export const requirements = [
  {
    re: /^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
    label: 'Содержит не менее 8 символов и только латинские символы'
  },
  { re: /[0-9]/, label: 'добавьте число' },
  { re: /[a-z]/, label: 'добавьте строчную букву' },
  { re: /[A-Z]/, label: 'добавьте заглавную букву' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'добавьте специальные символы (! “ # $ % ‘ () *).' }
];

export const getStrength = (password: string) => {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
};

export const fullRegExRequirements = new RegExp(
  requirements.map(({ re }) => `(${re.source})`).join('|')
);

export const generatePassword = (regex: RegExp, minLength?: number): string => {
  const characters = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789${allPasswordCharacters}`;

  let password = '';
  const minimumLength = minLength ? minLength : 8;
  while (!regex.test(password)) {
    password = '';
    for (let i = 0; i <= minimumLength; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
  }
  return password;
};

export const getPasswordStrength = (
  strength: number
): { color: string; strengthString: string } => {
  if (strength < 50) {
    return { color: '#FF6B6B', strengthString: 'ненадежный' };
  } else if (strength >= 50 && strength < 100) {
    return { color: '#FCC419', strengthString: 'вполне надежный' };
  } else if (strength === 100) {
    return { color: '#51CF66', strengthString: 'надежный' };
  } else {
    return { color: '', strengthString: '' };
  }
};

export function generatePasswordRegExp({
  options = {},
  maxLengthPassword = 30
}: IGeneratePasswordRegExp): RegExp {
  const characterClasses = [];

  if (options?.UseLowLetter) {
    characterClasses.push('(?=.*[a-z])');
  }

  if (options?.UseCapitalLetters) {
    characterClasses.push('(?=.*[A-Z])');
  }

  if (options?.UseDigital) {
    characterClasses.push('(?=.*[0-9])');
  }

  let allowedCharacters = 'a-zA-Z0-9';

  if (options?.UseSpecialCharacters) {
    characterClasses.push(`(?=.*[
      ${escapeRegExp(
        options?.UseAllowedSpecialCharacters
          ? escapeRegExp(allowedKeyboardSpecialCharacters)
          : escapeRegExp(keyboardSpecialCharacters)
      )}
    ])`);
  }

  allowedCharacters += options?.UseAllowedSpecialCharacters
    ? escapeRegExp(escapeRegExp(allowedKeyboardSpecialCharacters))
    : escapeRegExp(escapeRegExp(keyboardSpecialCharacters));

  const latinLettersWithConditions = `^${
    characterClasses.length > 0 ? characterClasses.join('') : ''
  }[${allowedCharacters}]{${
    options?.MinLength && options?.MinLength >= 8 && options?.MinLength <= 30
      ? options?.MinLength
      : 8
  },${maxLengthPassword}}$`;

  return new RegExp(latinLettersWithConditions);
}

// Вспомогательная функция для экранирования специальных символов
export function escapeRegExp(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function generateRandomPassword(length: number, options?: IPasswordSecurity): string {
  let characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  if (options?.UseAllowedSpecialCharacters) {
    characters += allowedKeyboardSpecialCharacters;
  } else if (options?.UseSpecialCharacters && !options?.UseAllowedSpecialCharacters) {
    characters += keyboardSpecialCharacters;
  }

  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    password += characters[randomIndex];
  }
  return password;
}
