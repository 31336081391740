import { Dispatch, SetStateAction, SyntheticEvent, useState } from 'react';
import { IColumnData, IRowData } from '@components/simple-table/types';
import { CustomCheckbox } from '../components/table/custom-checkbox';
import { ItemUser } from '../components/table/item-user';
import { Action } from '../components/table/action';
import { getColorsByRole } from '../utils';
import { RoleTypesEnum } from '@/types/enums/user';
import { formatDate } from '@/lib/utils/date';
import { numberToUserStatus } from '@/lib/utils';
import { RolesList } from '@/containers/pages/users/components/table/rolesColumn';
import { ParentUserNames } from '@/containers/pages/users/components/table/ParentUserName';
import { useTranslation } from 'react-i18next';
import { formatByteToGb, formatDataSizeUnits } from '@/lib/utils/format';
import { ItemEmail } from '@/containers/pages/users/components/table/emailColumn';

export type IndexColumnsType =
  | 'Checkbox'
  | 'Name'
  | 'Login'
  | 'Type'
  | 'Email'
  | 'Phone'
  | 'Customer'
  | 'ParentUserName'
  | 'BirthDate'
  | 'DateOfCreate'
  | 'LastVisitDate'
  | 'Status'
  | 'QuotaName'
  | 'QuotaSize'
  | 'UsingQuotaSize'
  | 'Action';

export const useGetColumns = (
  toggleRow: (event: SyntheticEvent<HTMLInputElement>) => void,
  setRowData: Dispatch<SetStateAction<IRowData[]>>,
  columnsVisibility: Record<IndexColumnsType, boolean>,
  scrollRef?: any
) => {
  const { t } = useTranslation();
  const columnsData: IColumnData[] = [
    {
      width: '32px',
      dataIndex: 'Checkbox',
      title: '',
      hideColumn: false,
      render: (item: IRowData, selection?: number[]) => (
        <CustomCheckbox selection={selection} toggleRow={toggleRow} item={item} />
      )
    },
    {
      width: '292px',
      dataIndex: 'Name',
      title: t('profile.firstName'),
      hideColumn: columnsVisibility['Name'],
      hasSort: true,
      render: (item: IRowData) => <ItemUser item={item} />
    },
    {
      width: '220px',
      dataIndex: 'Login',
      title: t('profile.login'),
      hideColumn: columnsVisibility['Login'],
      hasSort: true,
      render: (item: IRowData) => item?.Login
    },
    {
      width: '292px',
      dataIndex: 'Type',
      title: t('profile.role'),
      hideColumn: columnsVisibility['Type'],
      render: (item: IRowData) => <RolesList roles={item?.Roles || []} />
    },
    {
      width: '235px',
      dataIndex: 'Email',
      title: t('filials.authorization.mail'),
      hideColumn: columnsVisibility['Email'],
      hasSort: true,
      render: (item: IRowData) => <ItemEmail item={item} />
    },
    {
      width: '220px',
      dataIndex: 'Phone',
      title: t('register.phone'),
      hideColumn: columnsVisibility['Phone'],
      hasSort: true,
      render: (item: IRowData) => item?.PhoneNumber
    },
    {
      width: '220px',
      dataIndex: 'Customer',
      title: t('unit'),
      hideColumn: columnsVisibility['Customer'],
      hasSort: true,
      render: (item: IRowData) => item?.Customer?.Name
    },
    {
      width: '220px',
      dataIndex: 'ParentUserName',
      title: t('roles.leader'),
      hideColumn: columnsVisibility['ParentUserName'],
      render: (item: IRowData) => <ParentUserNames item={item?.Positions} />
    },
    {
      width: '220px',
      dataIndex: 'BirthDate',
      title: t('profile.birthDate'),
      hideColumn: columnsVisibility['BirthDate'],
      hasSort: true,
      render: (item: IRowData) => formatDate(item?.BirthDate)
    },
    {
      width: '220px',
      dataIndex: 'DateOfCreate',
      title: t('dateOfCreation'),
      hideColumn: columnsVisibility['DateOfCreate'],
      hasSort: true,
      render: (item: IRowData) => formatDate(item?.DateOfCreate)
    },
    {
      width: '280px',
      dataIndex: 'LastVisitDate',
      title: t('profile.lastEntryDate'),
      hideColumn: columnsVisibility['LastVisitDate'],
      hasSort: true,
      render: (item: IRowData) => formatDate(item?.LastVisitDate)
    },
    {
      width: '280px',
      dataIndex: 'Status',
      title: t('profile.userStatus'),
      hideColumn: columnsVisibility['Status'],
      hasSort: true,
      render: (item: IRowData) => numberToUserStatus({ value: item?.Status, t })
    },
    {
      width: '220px',
      dataIndex: 'QuotaName',
      title: t('profile.userQuota'),
      hideColumn: columnsVisibility['QuotaName'],
      hasSort: true,
      render: (item: IRowData) => item?.DataStorageQuota?.QuotaName
    },
    {
      width: '220px',
      dataIndex: 'QuotaSize',
      title: t('quota.size'),
      hideColumn: columnsVisibility['QuotaSize'],
      hasSort: true,
      render: (item: IRowData) => formatByteToGb(item?.DataStorageQuota?.QuotaSize)
    },
    {
      width: '280px',
      dataIndex: 'UsingQuotaSize',
      title: t('quota.usedSpace'),
      hideColumn: columnsVisibility['UsingQuotaSize'],
      hasSort: true,
      render: (item: IRowData) => formatDataSizeUnits(item?.DataStorageQuota?.UsingQuotaSize)
    },
    // {
    //   width: '220px',
    //   dataIndex: 'Address',
    //   title: 'Местоположение',
    //   hideColumn: true,
    //   render: (item: IRowData) => item?.Address
    // },
    {
      width: '50px',
      dataIndex: 'Action',
      title: '',
      hideColumn: columnsVisibility['Action'],
      render: (item: IRowData) => (
        <Action scrollRef={scrollRef} item={item} setRowData={setRowData} />
      )
    }
  ];
  // const fullColumnsData: IColumnData[] = columnsData.map((columnData, i) => {
  //   return {
  //     ...columnData,
  //     hideColumn: columnsVisibility[i] ?? false
  //   };
  // });
  const simpleColumns: IColumnData[] = [
    {
      width: '32px',
      dataIndex: 'Checkbox',
      title: '',
      hideColumn: false,
      render: () => ''
    },
    {
      width: '292px',
      dataIndex: 'Name',
      title: t('profile.firstName'),
      hideColumn: false,
      render: (item: IRowData) =>
        `${item?.Surname ?? ''} ${item?.FirstName ?? ''} ${item?.MiddleName ?? ''}`
    },
    {
      width: '120px',
      dataIndex: 'Type',
      title: t('profile.role'),
      hideColumn: false,
      render: (item: IRowData) => {
        return item?.RoleTypes?.map((role: RoleTypesEnum) => {
          const { status } = getColorsByRole(role);
          return status;
        })
          .join()
          .trim();
      }
    },
    // {
    //   width: '146px',
    //   dataIndex: 'Department',
    //   title: 'Отдел',
    //   hideColumn: false,
    //   render: (item: IRowData) => item.Department
    // },
    {
      width: '235px',
      dataIndex: 'Email',
      title: t('filials.authorization.mail'),
      hideColumn: false,
      render: (item: IRowData) => item?.Email
    },
    {
      width: '220px',
      dataIndex: 'Phone',
      title: t('register.phone'),
      hideColumn: false,
      render: (item: IRowData) => item?.Phone
    },
    {
      width: '50px',
      dataIndex: 'Action',
      title: '',
      hideColumn: false,
      render: () => ''
    }
  ];

  const [columns, setColumns] = useState<IColumnData[]>(columnsData);

  return { columns, setColumns, simpleColumns };
};
