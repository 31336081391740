import { FC, useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { Dropzone } from '@mantine/dropzone';
import { Box, Slider, Text } from '@mantine/core';
import ReactAvatarEditor from 'react-avatar-editor';
import { useStyles } from './styles';
import { IconUpload, IconCircleMinus, IconCirclePlus } from '@tabler/icons';
import { themeColors } from '@/theme/colors';
import { useTranslation } from 'react-i18next';

interface IAvatarEditorProps {
  avatar: string;
  setAvatar: (value: string) => void;
  openRef?: React.Ref<any>;
  avatarRef?: any;
}

export const AvatarEditor: FC<IAvatarEditorProps> = ({ setAvatar, avatar, openRef, avatarRef }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [scale, setScale] = useState(1);
  const [hint, setHint] = useState(true);

  useEffect(() => {
    setScale(1);
  }, [avatar]);

  const onBeforeFileLoad = (files: any) => {
    if (files[0].file.size > 5242880) {
      showNotification({
        title: t('avatar.warning'),
        message: '',
        autoClose: true,
        color: 'red'
      });
    }
  };

  const onDrop = async (files: any) => {
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e?.target?.result) {
          setAvatar(e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleScaleChange = (newScale: number) => {
    setScale(newScale);
  };

  const increaseScale = () => {
    if (scale < 5) {
      setScale(scale + 0.1);
    }
  };

  const decreaseScale = () => {
    if (scale > 1) {
      setScale(scale - 0.1);
    }
  };

  const handleDropzoneTouchStart = () => {
    setHint(false);
  };

  return (
    <Box>
      <Dropzone
        activateOnClick={avatar ? false : true}
        styles={{ inner: { pointerEvents: 'all' } }}
        onDrop={onDrop}
        multiple={false}
        className={classes.dropZone}
        openRef={openRef}
        onReject={onBeforeFileLoad}
        maxSize={5 * 1024 ** 2}
        accept={['image/png', 'image/jpeg', 'image/jpg', 'image/bmp']}
      >
        {avatar ? (
          <>
            <ReactAvatarEditor
              ref={avatarRef}
              image={avatar}
              scale={scale}
              color={[0, 0, 0, 0.45]}
              style={{ width: '100%', height: '100%' }}
              borderRadius={avatar ? 125 : 0}
              border={0}
              className={classes.editor}
              onMouseMove={handleDropzoneTouchStart}
            />
            {hint && <Box className={classes.hint}>{t('avatar.hint')}</Box>}
          </>
        ) : (
          <Box>
            <IconUpload size={34} color={themeColors.brandGray[0]} stroke={2} />
            <Text className={classes.text}>{t('avatar.choosePhoto')}</Text>
          </Box>
        )}
      </Dropzone>
      {avatar && (
        <Box className={classes.slider}>
          <IconCircleMinus
            size={20}
            color={themeColors.brandGray[0]}
            stroke={1.6}
            onClick={decreaseScale}
            cursor="pointer"
          />
          <Slider
            className={classes.range}
            value={scale}
            onChange={handleScaleChange}
            max={5}
            min={1}
            step={0.1}
            label={null}
          />
          <IconCirclePlus
            size={20}
            color={themeColors.brandGray[0]}
            stroke={1.6}
            onClick={increaseScale}
            cursor="pointer"
          />
        </Box>
      )}
    </Box>
  );
};
