import { useCallback } from 'react';
import { showNotification } from '@mantine/notifications';

import { IFilialSettings } from '@/entities/admin-app/filials';

import {
  useLazyGetFilialsInfoCommonQuery,
  useUpdateFilialSettingsMutation
} from '@/entities/admin-app/filials/api';
import { SettingsEnum } from '@/containers/pages/filials/utils';
import { useActions } from '@hooks/redux/action';

export const useSaveSettings = () => {
  const [updateSettings] = useUpdateFilialSettingsMutation();
  const { setFilial } = useActions();
  const [getUpdatedValue, { isSuccess, data: updatedData }] = useLazyGetFilialsInfoCommonQuery();
  return useCallback(async (id: number, type: SettingsEnum, values: Partial<IFilialSettings>) => {
    try {
      const res = await updateSettings({
        id,
        type,
        ...values
      });
      if ('error' in res) {
        showNotification({
          title: `${(res as any)?.error?.data?.Response.Data}`,
          message: '',
          autoClose: true,
          color: 'red'
        });
      } else {
        showNotification({
          title: `Подразделение успешно сохранено!`,
          message: '',
          autoClose: true,
          color: 'green'
        });
        getUpdatedValue({ id, extended: true });
        if (id && isSuccess && updatedData) {
          setFilial(updatedData);
        }
      }
    } catch (err: any) {
      console.warn('Ошибка сохранения подразделения', err);
    }
  }, []);
};
