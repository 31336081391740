import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@/utils/customBaseQuery';

import { BASE_URL } from '../constants';
import {
  IFilialsInfoCommon,
  IFilialsData,
  IFilialsCreateResponse,
  IFilialCommonForm,
  IFilialSettingsForm
} from './types';
import { IFullResponse, IQueryTableV1 } from '@/types/api';
import { IFilialsInitialFilters } from '@/types/enums/filials';

const tagTypes = ['Filials', 'Filters'];

export const filialsApi = createApi({
  reducerPath: 'api/filials',
  baseQuery: customBaseQuery(`${BASE_URL}/v2/customer/`),
  refetchOnFocus: false,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getFilials: build.query<IFilialsData, IQueryTableV1<IFilialsInitialFilters>>({
      query: (payload?: IQueryTableV1<IFilialsInitialFilters>) => ({
        url: `list`,
        body: {
          ...payload
        },
        method: 'post'
      }),

      transformResponse: (response: IFullResponse<IFilialsData>) => response.Response.Data,
      providesTags: ['Filials']
    }),

    getFilialsTree: build.query<IFilialsData, IQueryTableV1<IFilialsInitialFilters>>({
      query: (payload?: IQueryTableV1<IFilialsInitialFilters>) => ({
        url: `tree`,
        params: {
          ...payload
        },
        method: 'get'
      }),

      transformResponse: (response: IFullResponse<IFilialsData>) => response.Response.Data,
      providesTags: ['Filials']
    }),

    createFilial: build.mutation<IFilialsCreateResponse, IFilialCommonForm>({
      query(payload) {
        return {
          url: '',
          method: 'POST',
          body: { ...payload }
        };
      },

      transformResponse: (response: IFullResponse<IFilialsCreateResponse>) =>
        response.Response.Data,
      invalidatesTags: ['Filials']
    }),

    updateFilial: build.mutation<IFilialsCreateResponse, IFilialCommonForm>({
      query(payload) {
        return {
          url: '',
          method: 'PUT',
          body: { ...payload }
        };
      },

      transformResponse: (response: IFullResponse<IFilialsCreateResponse>) =>
        response.Response.Data,
      invalidatesTags: ['Filials']
    }),

    getFilialsInfoCommon: build.query<IFilialsInfoCommon, { id: number; extended?: boolean }>({
      query: ({ id, extended }) => ({
        params: {
          id,
          extended: extended || undefined
        },
        url: ``
      }),
      transformResponse: (response: IFullResponse<IFilialsInfoCommon>) => response.Response.Data,
      providesTags: ['Filials']
    }),

    updateFilialSettings: build.mutation<IFilialsInfoCommon, IFilialSettingsForm>({
      query: ({ id, type, ...rest }) => ({
        url: 'settings',
        method: 'post',
        body: {
          CustomerId: id,
          Type: type,
          Settings: rest
        }
      })
    }),

    ldapSettingsSync: build.mutation<IFilialsInfoCommon, { id: number }>({
      query: ({ id }) => ({
        params: {
          customerid: id
        },
        url: 'startldapSync',
        method: 'get'
      })
    }),

    deleteFilials: build.mutation<void, number>({
      query: (id: number) => ({
        url: `?id=${id}`,
        method: 'delete'
      }),
      invalidatesTags: ['Filials']
    })
  })
});

export const {
  useGetFilialsQuery,
  useGetFilialsTreeQuery,
  useCreateFilialMutation,
  useUpdateFilialMutation,
  useGetFilialsInfoCommonQuery,
  useLazyGetFilialsInfoCommonQuery,
  useUpdateFilialSettingsMutation,
  useLdapSettingsSyncMutation,
  useDeleteFilialsMutation,
  useLazyGetFilialsQuery
  // useGetFiltersQuery,
  // useRemoveFilialsMutation,
  // useCheckRemoveStatusMutation
} = filialsApi;
