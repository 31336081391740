import { useContext } from 'react';
import { CreateUserContext } from '@/containers/pages/user/create-user';
import { FilialInfo } from './filial-info';
import { GeneralInfo } from './general-info';
import { Quotas } from './quotas';
import { Control } from './control';

interface ISectionsProps {
  isLoading: boolean;
}

export const Sections = ({ isLoading }: ISectionsProps) => {
  const { form } = useContext(CreateUserContext);
  return (
    <>
      <GeneralInfo />
      <FilialInfo />
      <Quotas />
      <Control form={form} isLoading={isLoading} />
    </>
  );
};
