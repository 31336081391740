import React, { FC } from 'react';
import { Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { IRole } from '@/entities/admin-app/directories';

interface IRolesListProps {
  roles: IRole[] | null | undefined;
}

export const RolesList: FC<IRolesListProps> = ({ roles }) => {
  const { classes } = useStyles();

  if (!Array.isArray(roles)) {
    return null;
  }

  return (
    <Box className={classes.listGroup}>
      {roles?.slice(0, 5).map((role, index) => {
        return (
          <Box key={index} maw={200}>
            <Text truncate data-text={role?.Name} className={classes.rounded}>
              {role?.Name}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
};
