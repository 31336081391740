import { FC } from 'react';
import { Avatar, Box, Indicator, Text } from '@mantine/core';
import { useStyles } from './styles';
import { IRowData } from '@components/simple-table/types';
import { UserStatusEnum } from '@/types/enums/user';
import { IPosition } from '@/entities/admin-app/user';

interface IItemUserProps {
  item?: IRowData;
}

export const ItemUser: FC<IItemUserProps> = ({ item }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.user}>
      <Indicator
        label={item?.Status === UserStatusEnum.Ban ? 'B' : ''}
        size={item?.Status === UserStatusEnum.Ban ? 13 : 0}
        offset={6}
        position="bottom-start"
        color="red"
        sx={{
          '& .mantine-Indicator-indicator': {
            fontSize: '7px',
            fontWeight: 600,
            lineHeight: 'normal'
          }
        }}
      >
        <Avatar sx={{ zIndex: 1 }} size={40} src={item?.Avatar} radius={40} />
      </Indicator>
      <Box className={classes.content} maw="200px">
        <Box maw="200px">
          <Text truncate size="sm" weight={600} color="#101828">
            {item?.Name}
          </Text>
        </Box>
        {item?.Positions?.length
          ? item?.Positions?.slice(0, 2).map((position: IPosition, index: number) => (
              <Box key={index} maw="200px">
                <Text truncate size="xs" weight={400}>
                  {position?.Position}
                </Text>
              </Box>
            ))
          : null}
      </Box>
    </Box>
  );
};
