import i18n from '@/entities/i18next';
export enum SizeEnum {
  kilobyte = 'kilobyte',
  megabyte = 'megabyte',
  gigabyte = 'gigabyte',
  terabyte = 'terabyte'
}

const BYTES_IN_GB = 1024 * 1024 * 1024;
const BYTES_IN_MB = 1024 * 1024;
const BYTES_IN_KB = 1024;

export const formatSize = (size: number, unit: SizeEnum = SizeEnum.megabyte) => {
  if (!size || size < 1) {
    return '0';
  }

  const gigabytes = unit === SizeEnum.gigabyte ? size : size / 1024;
  const options = {
    style: 'unit',
    unit: gigabytes < 1 ? SizeEnum.megabyte : SizeEnum.gigabyte
  };
  return new Intl.NumberFormat('ru-RU', options).format(gigabytes < 1 ? size : gigabytes);
};

export const formatNumber = (number: number) => {
  return new Intl.NumberFormat('ru-RU').format(number);
};

export const formatByteToGb = (size: number | undefined | null): string => {
  if (!size || isNaN(size) || size < 1) {
    return '0';
  }

  const marker = BYTES_IN_GB;
  const gigabytes = parseFloat((size / marker).toFixed(4));
  const options = {
    style: 'unit',
    unit: SizeEnum.gigabyte
  };
  return gigabytes > 0 ? new Intl.NumberFormat('ru-RU', options).format(gigabytes) : '0';
};

export const formatDataSizeUnits = (size: number | undefined | null): string => {
  if (!size || isNaN(size) || size < 1) {
    return '0';
  }

  const terabytes = size / (BYTES_IN_GB * 1024);
  const gigabytes = size / BYTES_IN_GB;
  const megabytes = size / BYTES_IN_MB;
  const kilobytes = size / BYTES_IN_KB;

  let formattedSize;
  let unit: SizeEnum;

  if (terabytes >= 1) {
    formattedSize = parseFloat(terabytes.toFixed(1));
    unit = SizeEnum.terabyte;
  } else if (gigabytes >= 1) {
    formattedSize = parseFloat(gigabytes.toFixed(1));
    unit = SizeEnum.gigabyte;
  } else if (megabytes >= 1) {
    formattedSize = parseFloat(megabytes.toFixed(1));
    unit = SizeEnum.megabyte;
  } else {
    formattedSize = parseFloat(kilobytes.toFixed(1));
    unit = SizeEnum.kilobyte;
  }

  const options = {
    style: 'unit',
    unit: unit
  };

  return new Intl.NumberFormat(i18n.language || 'ru-Ru', options)
    .format(formattedSize)
    ?.toUpperCase();
};

export const convertByteToGb = (size: number | undefined | null): number => {
  if (!size || isNaN(size) || size < 0) {
    return 0;
  }

  const marker = BYTES_IN_GB;
  return parseFloat((size / marker).toFixed(2));
};

export const convertGbToByte = (size: number | undefined | null): number => {
  if (!size || isNaN(size) || size < 0) {
    return 0;
  }

  const marker = BYTES_IN_GB;
  return size * marker;
};
