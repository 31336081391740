import React from 'react';
import {
  Button,
  LoadingOverlay,
  // Modal,
  Box,
  UnstyledButton,
  PasswordInput,
  SimpleGrid,
  TextInput
} from '@mantine/core';
import { useStyles } from '../styles';
import { showNotification } from '@mantine/notifications';
import { useLazyCheckExistsEmailForLinkQuery } from '@/entities/admin-app/email/api';
import { ILinkedEmail } from '@/entities/admin-app/email';
import { Modal } from '@/components/modal';
import { FormErrors, useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { getValidateErrors /*, passwordReg*/ } from '@/lib/utils/validation';
import { z } from 'zod';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';

interface ILinkEmailModalProps {
  handleAddEmail?: (data: ILinkedEmail) => void;
  opened: boolean;
  handleClose: () => void;
  filialId?: number | null;
}
export const LinkEmailModal = ({
  handleAddEmail,
  opened,
  handleClose,
  filialId
}: ILinkEmailModalProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [checkLinkEmail, { isError, isFetching }] = useLazyCheckExistsEmailForLinkQuery();

  const linkFormSchema = z.object({
    Email: z.string().email({ message: t('emailFormatError') }),
    Password: z
      .string()
      // .regex(passwordReg, {
      //   message: t('passwordRegError')
      // })
      .min(8, { message: t('passwordMinError') })
  });

  const linkForm = useForm({
    initialValues: {
      Email: '',
      Password: ''
    },
    validateInputOnChange: true,
    validate: (values: any): FormErrors => getValidateErrors(linkFormSchema, values)
  });
  const modalClose = () => {
    linkForm.reset();
    handleClose();
  };

  const handleSubmit = async () => {
    const { Email, Password } = linkForm.values;
    try {
      const res = await checkLinkEmail({
        Email: Email,
        CustomerId: filialId
      });

      if ('error' in res) {
        const codeError = extractErrorCode(res.error);
        showNotification({
          title: generateErrorMessage(codeError),
          message: '',
          autoClose: true,
          color: 'red'
        });
      } else {
        if (handleAddEmail && res.data) {
          handleAddEmail({
            Id: res.data.Id,
            Password: Password,
            UserId: res.data.UserId,
            Email: Email,
            EmailServer: { Id: res?.data?.EmailServer?.Id || 0, Name: res?.data?.EmailServer?.Name }
          });
        }
        modalClose();
      }
    } catch (err: any) {
      console.warn('Ошибка добавления учетной записи', err);
    }
  };

  return (
    <Modal opened={opened} onClose={modalClose} title="Введите данные учетной записи" zIndex={1300}>
      <Box className={classes.modalRoot}>
        <Box className={classes.modalContainer}>
          <SimpleGrid cols={1} w="100%">
            <TextInput
              className={classes.textFieldInput}
              placeholder={t('profile.email')}
              label="Е-мейл"
              withAsterisk
              {...linkForm.getInputProps('Email')}
            />
            <PasswordInput
              className={classes.textFieldInput}
              placeholder={t('profile.password')}
              label="Пароль"
              withAsterisk
              {...linkForm.getInputProps('Password')}
            />
          </SimpleGrid>
        </Box>
        <Box className={classes.modalFooter}>
          <Button
            size="sm"
            radius={8}
            w="49%"
            disabled={!linkForm.isValid()}
            onClick={handleSubmit}
          >
            {t('profile.add')}
          </Button>
          <UnstyledButton ml={8} className={classes.modalCancelBtn} w="49%" onClick={modalClose}>
            {t('cancel')}
          </UnstyledButton>
        </Box>
        <LoadingOverlay visible={isFetching && !isError} overlayBlur={2} />
      </Box>
    </Modal>
  );
};
