import { showNotification } from '@mantine/notifications';
import { useCloseseSsionsMutation } from '@/entities/admin-app/user/api';
import { useTranslation } from 'react-i18next';

export const useTerminateSessions = () => {
  const { t } = useTranslation();
  const [fetchCloseSessions, { isLoading, isSuccess, isError }] = useCloseseSsionsMutation();

  const closeSessions = async (
    options: { ids?: number[]; userId?: number | null },
    fn?: () => void
  ) => {
    const { ids, userId } = options;

    const successText = t(
      `profile.${!ids ? 'allConnectionsTerminatedSuccess' : 'oneConnectionTerminatedSuccess'}`
    );
    try {
      const requestData = { Ids: ids, UserId: userId };
      const res: any = await fetchCloseSessions(requestData);

      if (!Object.keys(res).includes('error')) {
        fn && fn();
        showNotification({
          title: successText,
          message: ``,
          autoClose: true,
          color: 'green'
        });
      } else {
        showNotification({
          title: `Ошибка`,
          message: `${res?.error.message}`,
          autoClose: true,
          color: 'red'
        });
      }
    } catch (err) {
      console.warn('useTerminateSessions ERROR', err);
    }
  };

  return { closeSessions, isLoading, isSuccess, isError };
};
