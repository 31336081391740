import { FC } from 'react';
import { Box, Button, UnstyledButton } from '@mantine/core';
import { useStyles } from './styles';
import { IControlSectionProps } from '..';

interface ISelectUserModalProps {
  handleClose: () => void;
  userId?: number;
  controlSectionProps?: IControlSectionProps;
}

export const ControlSection: FC<ISelectUserModalProps> = ({
  handleClose,
  userId,
  controlSectionProps
}) => {
  const { classes } = useStyles();

  const handleCancel = () => {
    if (controlSectionProps?.cancelProps.fn) {
      controlSectionProps?.cancelProps.fn();
    } else {
      handleClose();
    }
  };

  const handleConfirm = () => {
    controlSectionProps?.confirmProps?.fn && controlSectionProps?.confirmProps?.fn();
  };

  return (
    <Box className={classes.control}>
      <Button size="sm" radius={8} w="49%" disabled={!userId} onClick={handleConfirm}>
        {controlSectionProps?.confirmProps.btnName ?? 'Добавить'}
      </Button>

      <UnstyledButton ml={8} className={classes.cancelBtn} w="49%" onClick={handleCancel}>
        {controlSectionProps?.cancelProps.btnName ?? 'Отмена'}
      </UnstyledButton>
    </Box>
  );
};
