import { ReactNode, FC } from 'react';
import { IconSettings } from '@tabler/icons';
import {
  CalendarExtraIcon,
  DriveIcon,
  DrawIcon,
  FileIcon,
  FilialsIcon,
  GroupsIcon,
  MailIcon,
  ShieldIcon,
  UsersIcon,
  ActivityIcon,
  SettingsIcon,
  RocketIcon,
  ContactsIcon
} from '@assets/icons';
import { IListItem } from '@/types';
import { PanelsEnum } from '@/types/enums/user';
import { EmailInfoPanelsEnum, DrawInfoPanelsEnum } from '@/types/enums/modules';

import { ROUTES } from '@/containers/routes/constants';

export interface INavbarMenuItem {
  label: string;
  checked: boolean;
  value: number;
  counter: number;
}

export interface INavbarMenu {
  label: string;
  icon: ReactNode | FC | null;
  value: string;
  counter?: number;
  initiallyOpened?: boolean;
  list?: INavbarMenuItem[];
  hideCollapse?: boolean;
}

export interface IMainNavbarMenu {
  pages: INavbarMenu[];
  typeUsers: INavbarMenuItem[];
}

export const controlMenuPages: INavbarMenu[] = [
  { label: 'Активность', icon: ActivityIcon, value: ROUTES.statistics.links.activity },
  {
    label: 'Дисковое пространство',
    icon: DriveIcon,
    value: ROUTES.statistics.links.diskSpace
  },
  { label: 'Журнал аудита', icon: FileIcon, value: ROUTES.statistics.links.audit }
];

export const menuProfilePage: readonly INavbarMenu[] = [
  { label: 'Личные данные', icon: UsersIcon, value: ROUTES.profile.pages.personalData },
  { label: 'Компания', icon: FilialsIcon, value: ROUTES.profile.pages.filials },
  { label: 'Безопасность', icon: ShieldIcon, value: ROUTES.profile.pages.safety },
  { label: 'Почтовые аккаунты', icon: MailIcon, value: ROUTES.profile.pages.mailAccount },
  { label: 'Диск', icon: DriveIcon, value: ROUTES.profile.pages.disk }
];
export const menuSettingsPage: readonly INavbarMenu[] = [
  { label: 'Брендирование', icon: RocketIcon, value: ROUTES.commonSettings.pages.branding },
  { label: 'Интеграции', icon: SettingsIcon, value: ROUTES.commonSettings.pages.integrations }
  // {
  //   label: 'Кастомизация',
  //   icon: CustomisationIcon,
  //   value: ROUTES.commonSettings.pages.customisation
  // }
];

export const menuPages: INavbarMenu[] = [
  { label: 'Пользователи', icon: <UsersIcon />, value: 'users' },
  { label: 'Группы', icon: <GroupsIcon />, value: 'roles' },
  { label: 'Настройки', icon: <IconSettings />, value: 'settings' }
];

export const userInfoSections: IListItem[] = [
  { label: 'Статус', value: PanelsEnum.status },
  { label: 'Учетные данные', value: PanelsEnum.credentials },
  { label: 'Общая информация', value: PanelsEnum.personalData },
  { label: 'Подразделение', value: PanelsEnum.company },
  { label: 'Должность', value: PanelsEnum.positions },
  { label: 'Роли', value: PanelsEnum.roles },
  { label: 'Учетные записи в модуле почта', value: PanelsEnum.mailAccount },
  { label: 'Политика хранения', value: PanelsEnum.disk }
];

export const modulesMenuPages: INavbarMenu[] = [
  { label: 'Почта', icon: MailIcon, value: ROUTES.filials.edit.pages.modules.email },
  {
    label: 'Календарь',
    icon: CalendarExtraIcon,
    value: ROUTES.filials.edit.pages.modules.calendar
  },
  { label: 'Графика', icon: DrawIcon, value: ROUTES.filials.edit.pages.modules.draw },
  { label: 'Контакты', icon: ContactsIcon, value: ROUTES.filials.edit.pages.modules.contacts }
];

export const driveInfoSections = [
  // { label: 'Квоты', value: DriveInfoPanelsEnum.quotas },
  // { label: 'Внешние ссылки', value: DriveInfoPanelsEnum.externalLinks }
] as const;

export const emailInfoSections = [
  { label: 'Доступ к модулю по ролям', value: EmailInfoPanelsEnum.byRoles },
  { label: 'Доступ к модулю по пользователям', value: EmailInfoPanelsEnum.byUsers }
] as const;

export const calendarInfoSections = [
  { label: 'Доступ к модулю по ролям', value: EmailInfoPanelsEnum.byRoles },
  { label: 'Доступ к модулю по пользователям', value: EmailInfoPanelsEnum.byUsers }
] as const;

export const drawInfoSections = [
  { label: 'Доступ к модулю по ролям', value: DrawInfoPanelsEnum.byRoles },
  { label: 'Доступ к модулю по пользователям', value: DrawInfoPanelsEnum.byUsers }
] as const;
export const contactsInfoSections = [
  { label: 'Доступ к модулю по ролям', value: DrawInfoPanelsEnum.byRoles },
  { label: 'Доступ к модулю по пользователям', value: DrawInfoPanelsEnum.byUsers }
] as const;
