import { useEffect, useState } from 'react';
import { useGetDefaultRolesFiltersQuery } from '@/entities/admin-app/roles/api';
import { IFilterMenu } from '@/types/api';
import { filtersLabels } from '@/entities/admin-app/constants';

export const useGetRolesFilters = () => {
  const { data: defaultFiltersData, isSuccess: defaultFiltersSuccess } =
    useGetDefaultRolesFiltersQuery(20);

  const [filtersMenuList, setFiltersMenuList] = useState<IFilterMenu[]>([]);

  useEffect(() => {
    if (defaultFiltersData && defaultFiltersSuccess) {
      const convertDefaultFilters: IFilterMenu[] = defaultFiltersData
        ?.map((item) => ({
          ...item,
          label: filtersLabels[item.Type] || 'Заголовок',
          Items: item.Items?.map((filterItem) => ({ ...filterItem, checked: false })),
          hideSearch: false
        }))
        .sort((a, b) => a.Type - b.Type);
      setFiltersMenuList(convertDefaultFilters);
    }
  }, [defaultFiltersData, defaultFiltersSuccess]);

  return { filtersMenuList };
};
