import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQueryTable } from '@/types/api';
import { IUsersInitialFilters } from '@/types/enums/users';
import { IUserTableData, IUsersData } from './types';
import { IndexColumnsType } from '@/containers/pages/users/hooks/useGetColumns';

interface IUsers {
  users: IUserTableData[];
  total: number;
  filters: IQueryTable<IUsersInitialFilters>;
  selectedUserId: number;
  selected: number[];
  columnVisibility: Record<IndexColumnsType, boolean>;
}

const initialState: IUsers = {
  users: [],
  total: 0,
  filters: {
    Count: 20,
    Offset: 0,
    SortOrder: 0,
    SortField: 0,
    Filters: {},
    Query: '',
    FilterConditions: []
  },
  selectedUserId: 0, // for show UserInfoMain when selectedUserId !== 0
  selected: [],
  columnVisibility: {
    Checkbox: false,
    Name: false,
    Login: true,
    Type: false,
    Email: false,
    Phone: false,
    Customer: false,
    ParentUserName: true,
    BirthDate: true,
    DateOfCreate: true,
    LastVisitDate: true,
    Status: true,
    QuotaName: true,
    QuotaSize: true,
    UsingQuotaSize: true,
    Action: false
  }
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<IUsersData>) {
      state.users = action.payload.Users;
      state.total = action.payload.TotalCount;
    },
    setUsersTableFilters(state, action: PayloadAction<IQueryTable<IUsersInitialFilters>>) {
      state.filters = action.payload;
    },
    setSelectedUserId(state, action: PayloadAction<number>) {
      state.selectedUserId = action.payload;
    },
    setSelectedUsersIds(state, action: PayloadAction<number>) {
      state.selected.includes(action.payload)
        ? (state.selected = state.selected.filter((state) => state !== action.payload))
        : (state.selected = [...state.selected, action.payload]);
    },
    setSelectedAllUsers(state, action: PayloadAction<number[]>) {
      state.selected = state.selected.length === action.payload.length ? [] : action.payload;
    },
    clearUsersSelected(state) {
      state.selected = [];
    },
    setColumnVisibility(state, action: PayloadAction<Record<IndexColumnsType, boolean>>) {
      state.columnVisibility = action.payload;
    }
  }
});

export const usersActions = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
