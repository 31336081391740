import React, { FC, useContext } from 'react';
import { UserInfoMainContext } from '..';
import { DrawerHeader, PhotoInfo, BaseInfo, MoreActions } from '../../components';

export const HomePanel: FC = () => {
  const { userInfoCommon } = useContext(UserInfoMainContext);

  return (
    <>
      <DrawerHeader />
      <PhotoInfo userInfoCommon={userInfoCommon} />
      <BaseInfo />
      <MoreActions />
    </>
  );
};
