import { useCallback, useEffect, useRef, useState } from 'react';
import { SimpleTable } from '@components/simple-table';
import { IRowData } from '@components/simple-table/types';
import { useGetRolesQuery } from '@/entities/admin-app/roles/api';
import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';
import { Box } from '@mantine/core';
import { useGetRolesFilters } from '../../hooks/useGetRolesFilters';
import { useGetColumns } from '../../hooks/useGetColumns';
import { FilterList } from '../filter/filter-list';
import { convertToFilterConditions, transformConditionals } from '../../utils';
import { IChangeFilterData, IFilterMenu } from '@/types/api';
import { useStyles } from './styles';

type FilterValue = number[] | Date[];
type ActiveFilters = Record<number, FilterValue>;

const RolesTable = () => {
  const { setRoles, setRolesTableFilters, setSelectedRolesId } = useActions();
  const { filtersMenuList } = useGetRolesFilters();
  const {
    items,
    total,
    filters: rolesFilter,
    selected: selectedRoles
  } = useAppSelector((state) => state.roles);

  const scrollRef = useRef<any>(null);
  const { classes } = useStyles();

  const [rowData, setRowData] = useState<IRowData[]>([]);
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>(
    rolesFilter?.FilterConditions ? transformConditionals(rolesFilter?.FilterConditions) : {}
  );
  const [rolesFilters, setRolesFilters] = useState<IFilterMenu[]>(filtersMenuList);

  useEffect(() => {
    if (filtersMenuList.length) {
      setRolesFilters(filtersMenuList);
    }
  }, [filtersMenuList]);

  const { data, error, isLoading } = useGetRolesQuery({
    Count: 20,
    Offset: rolesFilter.Offset,
    SortOrder: rolesFilter.SortOrder,
    SortField: rolesFilter.SortField,
    Filters: {},
    Query: rolesFilter.Query,
    FilterConditions: rolesFilter.FilterConditions
  });

  const { columns } = useGetColumns();

  const handleLoadMore = () => {
    setRolesTableFilters({
      ...rolesFilter,
      Offset: rolesFilter.Offset + 20
    });
  };

  const handleFilterChange = useCallback(
    ({ Type, value }: IChangeFilterData) => {
      const updatedData = { ...activeFilters };

      if (Array.isArray(value)) {
        updatedData[Type] = value;
      } else {
        if (typeof value === 'number') {
          const array = (updatedData[Type] as number[]) || [];
          updatedData[Type] = array.includes(value)
            ? array.filter((val) => val !== value)
            : [...array, value];
        }
      }

      setRolesTableFilters({
        ...rolesFilter,
        FilterConditions: convertToFilterConditions(updatedData),
        Offset: 0
      });
      scrollRef.current.el.scrollTo(0, 0);
      setActiveFilters(updatedData);
    },
    [rolesFilter]
  );
  const resetActiveFilterByType = (type: number) => {
    setActiveFilters((prevNumbers) => {
      const updatedNumbers = { ...prevNumbers };
      delete updatedNumbers[type];

      setRolesTableFilters({
        ...rolesFilter,
        FilterConditions: convertToFilterConditions(updatedNumbers),
        Offset: 0
      });

      return updatedNumbers;
    });
  };

  const resetAllActiveFilters = () => {
    setActiveFilters({});
    setRolesTableFilters({
      ...rolesFilter,
      FilterConditions: [],
      Offset: 0,
      Query: ''
    });
  };

  useEffect(() => {
    if (data) {
      const filialsData = rolesFilter.Offset > 0 ? [...items, ...data.Items] : data.Items;
      setRoles({ ...data, Items: filialsData });
    }
  }, [data]);

  useEffect(() => {
    setRowData(items || []);
  }, [items]);

  useEffect(() => {
    return () => {
      resetAllActiveFilters();
    };
  }, []);

  const handleRowClick = useCallback((id: number) => {
    setSelectedRolesId(id);
  }, []);

  return (
    <>
      <Box className={classes.listWrapper}>
        <FilterList
          handleFilterReset={resetActiveFilterByType}
          handleResetFilters={resetAllActiveFilters}
          activeFilters={activeFilters}
          handleFilterChange={handleFilterChange}
          filtersMenuList={rolesFilters}
        />
      </Box>
      <SimpleTable
        allDataLength={total}
        rowData={rowData}
        setRowData={setRowData}
        columns={columns}
        search={rolesFilter.Query}
        height="calc(100vh - 265px)"
        minWidth={700}
        width="calc(100vw - 48px)"
        selection={selectedRoles}
        handleRowClick={handleRowClick}
        getNextData={handleLoadMore}
        error={error}
        scrollRef={scrollRef}
        isLoading={isLoading}
        activeFilters={activeFilters}
      />
    </>
  );
};

export default RolesTable;
