import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  dropdown: {
    padding: '0'
  },
  truncate: {
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word'
  }
}));
