import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  divider: {
    marginTop: theme.spacing.sm,
    marginBottom: theme.spacing.sm,
    borderTopColor: theme.colors.brandGray[1]
  },

  search: {
    marginBottom: theme.spacing.sm,
    '& input': {
      backgroundColor: theme.colors.backgroundGray[6],
      height: '48px',
      fontSize: '14px',
      [theme.fn.smallerThan('lg')]: {
        height: '100%'
      }
    }
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing.sm
  },

  title: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px'
  },

  infiniteScrollMoreData: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  infiniteScroll: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#98A2B3'
    },

    '&::-webkit-scrollbar-track': {
      background: '#F9FAFB'
    },

    scrollbarColor: '#98A2B3 #F9FAFB'
  },

  badge: {
    paddingRight: 3,
    backgroundColor: theme.colors.backgroundGray[6],
    color: 'black',
    borderRadius: '16px',
    marginRight: theme.spacing.xs,
    marginTop: theme.spacing.xs,
    border: 'none',
    textTransform: 'inherit',
    height: '28px',
    fontSize: '14px',
    padding: theme.spacing.sm,
    fontWeight: 400,
    cursor: 'pointer'
  },

  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },

  emptyListText: {
    fontSize: '14px',
    textAlign: 'center'
  }
}));
