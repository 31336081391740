import { FC, useContext, useEffect, useRef } from 'react';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { Positions } from '@/containers/pages/user/components/sections/general-info/positions';
import { EditUserInfoContext } from '../../../..';
import { useAppSelector } from '@/hooks/redux/redux';
import { isAdmin } from '@/lib/utils/access';
import { IUserPosition } from '@/entities/admin-app/user';
import { FormErrors, useForm } from '@mantine/form';
import { Box, Button, LoadingOverlay } from '@mantine/core';
import { getValidateErrors } from '@/lib/utils/validation';
import { validateSchemePositions } from '@/containers/pages/user/schemes/validate';
import { useUpdateInfo } from '@/hooks/users/useUpdateUser';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { useTranslation } from 'react-i18next';

const UserPositions: FC = () => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { userInfoCommon, isLimitedAccess, currentId } = useContext(EditUserInfoContext);
  const currentUser = useAppSelector((state) => state.userInfo.User);
  const { Positions: formPositions } = userInfoCommon;

  const { saveUpdate, isLoading } = useUpdateInfo();

  const userPositionsForm = useForm<{ Positions: IUserPosition[] | null } | null>({
    initialValues: {
      Positions: []
    },
    validateInputOnChange: true,
    validate: (values: any): FormErrors => {
      const intermediaryValidationErrors = getValidateErrors(
        validateSchemePositions,
        values.Positions
      );
      const suffixedValidations = Object.fromEntries(
        Object.entries(intermediaryValidationErrors).map(([key, value]) => [
          `Positions.${key}`,
          value
        ])
      );
      return suffixedValidations;
    }
  });

  const errors = userPositionsForm.errors;
  const isDirtyForm = userPositionsForm.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  useEffect(() => {
    const data = { Positions: formPositions };
    userPositionsForm.setValues(JSON.parse(JSON.stringify(data)));
    userPositionsForm.resetDirty(JSON.parse(JSON.stringify(data)));
  }, [formPositions]);

  const handleSubmit = async () => {
    const values = {
      Id: currentId,
      [ProfileSectionEnum.Positions]: userPositionsForm.values?.Positions ?? null
    };

    await saveUpdate({
      Type: ProfileUpdateTypeEnum.Positions,
      Data: values
    });
    // userPositionsForm.setValues(fromData);
    // userPositionsForm.resetDirty(fromData);
  };

  const handleError = (values: any) => {
    if (Object.keys(values).length) {
      for (let i = 0; i < Object.keys(values).length; i++) {
        const keyValue = Object.keys(values)[i];
        const elementWithInvalidData = containerRef?.current?.querySelector(
          `[data-name="${keyValue}"]`
        );
        if (elementWithInvalidData) {
          elementWithInvalidData.scrollIntoView({ behavior: 'smooth', block: 'center' });
          (elementWithInvalidData as HTMLInputElement).focus({ preventScroll: true });
          break;
        }
      }
    }
  };

  return (
    <form onSubmit={userPositionsForm.onSubmit(handleSubmit, handleError)}>
      <Box ref={containerRef} style={{ position: 'relative' }}>
        <SectionTemplate title={t('profile.position')}>
          <Positions
            Positions={userPositionsForm.values?.Positions ?? null}
            form={userPositionsForm}
            sectionFormKey="Positions"
            isLimitedAccess={isLimitedAccess}
          />
          {isAdmin(currentUser) && (
            <Button
              type="submit"
              size="sm"
              radius={8}
              disabled={isDisabled}
              mt="md"
              style={{ padding: '0 20px' }}
            >
              {t('profile.save')}
            </Button>
          )}
          <LoadingOverlay visible={isLoading} />
        </SectionTemplate>
      </Box>
    </form>
  );
};

export default UserPositions;
