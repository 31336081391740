import { useAppSelector } from '@hooks/redux/redux';
import { ErrorBoundary } from '@components/error-boundary';
import { FilialQuota } from './components/filial-quota';
import { UsersQuotaList } from './components/users-quota-list';
import { Flex } from '@mantine/core';
import { useStyles } from '../../../styles';

const DiskSpasePage = () => {
  const { classes } = useStyles();
  const { filial } = useAppSelector((state) => state.filials);

  return (
    <ErrorBoundary componentTitle="Disk space section">
      {filial && (
        <Flex className={classes.container} direction="column">
          <FilialQuota filial={filial} />
          <UsersQuotaList filial={filial} />
        </Flex>
      )}
    </ErrorBoundary>
  );
};

export default DiskSpasePage;
