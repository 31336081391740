import { Box, LoadingOverlay, Paper } from '@mantine/core';
import { ErrorBoundary } from '@components/error-boundary';
import { Layout } from '../../../layout';
import { SimpleNavbar } from '@components/simple-navbar';
import { useStyles } from './styles';
import { createContext, useEffect, useRef, useState } from 'react';
import { InfoSection } from '../components/info-sections';
import { PanelsEnum } from '@/types/enums/user';
import { Sections } from '../components/sections';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormErrors, useForm, UseFormReturnType } from '@mantine/form';
import iconUser from '@assets/icon-cant-create-user.svg';
import { getValidateErrors } from '@/lib/utils/validation';
import { z } from 'zod';
import { useAddMutation } from '@/entities/admin-app/user/api';
import { usersApi } from '@/entities/admin-app/users/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { IUserInfo } from '@/entities/admin-app/user';
import { showNotification } from '@mantine/notifications';
import { ROUTES } from '../../../routes/constants';
import { useMediaQuery } from '@mantine/hooks';
import { isApiResponse } from '@/entities/helpers';
import {
  validateSchemeCredentials,
  validateSchemeCustomer,
  validateSchemeDataStorageQuota,
  validateSchemeEditUserInfo,
  validateSchemePositions,
  validateSchemeStatus
} from '@/containers/pages/user/schemes/validate';
import { initForm } from '@/containers/pages/user/consts/initValues';
import { MessageContainer } from '@/components/message-container';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/hooks/redux/redux';
import { ModuleUrl } from '@/entities/admin-app/auth';
import { useLazyCheckCanCreateUserQuery } from '@/entities/admin-app/auth/api';
import { isSaaS } from '@/lib/utils/access';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';

export interface ICreateUserContext {
  form: UseFormReturnType<IUserInfo>;
  handleSubmit: (values: IUserInfo) => Promise<void>;
  activeSection: PanelsEnum;
}

export const CreateUserContext = createContext<ICreateUserContext>({} as ICreateUserContext);

export const CreateUser = () => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState<PanelsEnum>(
    location.state || PanelsEnum.status
  );
  const { Settings } = useAppSelector((state) => state.userInfo);
  const currentUser = useAppSelector((state) => state.userInfo.User);
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);

  const [checkCanCreate, { data: canCreateUserData, isSuccess: SuccessCheckCreateUser }] =
    useLazyCheckCanCreateUserQuery();

  useEffect(() => {
    if (currentUser && isSaaS(Settings)) {
      checkCanCreate({ Customerid: currentUser?.Customer.Id }).then(() => {
        setIsRequestCompleted(true);
      });
    }
  }, [currentUser]);

  const modules = useAppSelector(
    (state) => state.userInfo.Settings?.ModuleSettings?.ModuleUrls
  ) as ModuleUrl[];

  const paymentModule = Array.isArray(modules)
    ? modules.find((module) => module.Name === 'Payments')
    : undefined;

  const [fetchAddUser, { data, error, isError, isSuccess, isLoading }] = useAddMutation();

  const form = useForm<IUserInfo>({
    initialValues: {
      ...initForm,
      Credentials: {
        ...initForm.Credentials,
        isSaas: isSaaS(Settings)
      }
    },
    validateInputOnChange: true,
    validate: (values: IUserInfo): FormErrors => getValidateErrors(allValidateScheme, values)
  });

  const handleSubmit = async (values: IUserInfo) => {
    if (values.Credentials) {
      delete values.Credentials['isSaas'];
    }
    const quotaErrors = getValidateErrors(quotaValidationScheme, values);
    const isValidQuota = !Object.keys(quotaErrors).length;
    if (isValidQuota) {
      await fetchAddUser({ ...values, Quota: !values?.Quota?.Id ? null : values.Quota });
    } else {
      form.setErrors(quotaErrors);
    }
  };

  const isMobile = useMediaQuery('(max-width: 992px)');

  const initialValues: ICreateUserContext = {
    form,
    handleSubmit,
    activeSection
  };

  useEffect(() => {
    navigate(``, {
      state: activeSection
    });
  }, [activeSection]);

  useEffect(() => {
    if (isError && error) {
      if (isApiResponse(error)) {
        if ('data' in error) {
          const codeError = extractErrorCode(error);
          showNotification({
            title: `Ошибка ${error?.data?.ErrorCode || ''}`,
            message: generateErrorMessage(codeError),
            autoClose: true,
            color: 'red'
          });
        }
      }
      if (!isApiResponse(error)) {
        showNotification({
          title: `Ошибка при создании прользователя`,
          message: `Попробуйте снова`,
          autoClose: true,
          color: 'red'
        });
      }
    }

    if (isSuccess && data) {
      showNotification({
        title: `Пользователь ${
          isSaaS(Settings) ? data?.Credentials?.Email : data?.Credentials?.Login
        }`,
        message: `Успешно создан`,
        autoClose: true,
        color: 'green'
      });
      clearApiCacheByTags(usersApi, ['Users']);
      navigate(ROUTES.users.fullPath, { replace: true });
    }
  }, [data, error, isError, isSuccess]);

  const handleError = (values: any) => {
    if (Object.keys(values).length) {
      for (let i = 0; i < Object.keys(values).length; i++) {
        const keyValue = Object.keys(values)[i];
        const elementWithInvalidData = containerRef?.current?.querySelector(
          `[data-name="${keyValue}"]`
        );
        if (elementWithInvalidData) {
          elementWithInvalidData.scrollIntoView({ behavior: 'smooth', block: 'center' });
          (elementWithInvalidData as HTMLInputElement).focus({ preventScroll: true });
          break;
        }
      }
    }
  };

  return (
    <ErrorBoundary componentTitle="CreateUser page">
      {!isRequestCompleted && isSaaS(Settings) ? (
        <Layout>
          <LoadingOverlay visible={true} overlayBlur={10} />
        </Layout>
      ) : (canCreateUserData?.IsCanAddUser && SuccessCheckCreateUser) || !isSaaS(Settings) ? (
        <CreateUserContext.Provider value={initialValues}>
          <Layout
            // leftNavbarContent={<div className={classes.fakeNavbar}></div>}
            rightNavbarContent={
              <SimpleNavbar
                height={isMobile ? '100%' : 'calc(100vh - 80px)'}
                widthNav={290}
                className={classes.simpleNavbar}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              >
                <InfoSection
                  setActiveSection={setActiveSection}
                  activeSection={activeSection}
                  containerRef={containerRef}
                />
              </SimpleNavbar>
            }
          >
            <Box className={classes.container} ref={containerRef}>
              <form onSubmit={form.onSubmit(handleSubmit, handleError)}>
                <Sections isLoading={isLoading} />
              </form>
            </Box>
          </Layout>
        </CreateUserContext.Provider>
      ) : (
        <Layout>
          <Box className={classes.errorContainer}>
            <Paper className={classes.box} shadow="0 2px 8px 0 rgba(16, 24, 40, 0.1)" radius="8px">
              <MessageContainer
                icon={iconUser}
                description={t('profile.error.createLimit')}
                buttonText={t('profile.error.createLimitButton')}
                redirect={paymentModule?.Url}
              />
            </Paper>
          </Box>
        </Layout>
      )}
    </ErrorBoundary>
  );
};

const allValidateScheme = z.object({
  Credentials: validateSchemeCredentials,
  Common: validateSchemeEditUserInfo,
  Customer: validateSchemeCustomer,
  Positions: validateSchemePositions,
  Status: validateSchemeStatus
});

const quotaValidationScheme = z.object({
  Quota: validateSchemeDataStorageQuota
});
