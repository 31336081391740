import React, { FC, forwardRef } from 'react';
import { Flex, Text, Group, Radio, Select } from '@mantine/core';

import { themeColors } from '@/theme/colors';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface IHeaderProps {
  handleChangePageSize: (value: number) => void;
  pageSize: number | null;
  pageSizeOptions: { value: string; label: string }[];
  isLoading: boolean;
  startOffset: number;
  endOffset: number;
  totalCount: number;
  showButton?: boolean;
  buttonComponent?: React.ReactElement;
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image?: string;
  label: string;
  description?: string;
  value: string;
}
export const TableHeader: FC<IHeaderProps> = ({
  handleChangePageSize,
  pageSize,
  pageSizeOptions,
  isLoading,
  startOffset,
  endOffset,
  totalCount,
  showButton,
  buttonComponent
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(function SelectItem(
    { label, value, ...others }: ItemProps,
    ref
  ) {
    return (
      <div ref={ref} {...others} className={classes.selectItem}>
        <Group noWrap>
          <Radio defaultChecked={value === pageSize?.toString()} />
          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    );
  });

  let displayText;
  if (!isLoading) {
    let offsetsText = `${startOffset}`;
    if (endOffset !== startOffset) {
      offsetsText += `-${endOffset}`;
    }
    const recordsText =
      totalCount % 10 === 1 && totalCount % 100 !== 11
        ? t('statistics.records')
        : t('statistics.records_');
    displayText = `${offsetsText} ${t('statistics.from')} ${totalCount} ${recordsText}`;
  }

  return (
    <Flex justify={showButton ? 'space-between' : 'flex-end'} className={classes.controlWrapper}>
      {showButton && buttonComponent}
      <Flex align="center" className={classes.rightSection}>
        {isLoading ||
          (totalCount > 0 && (
            <Text className={classes.countText} color={themeColors.brandGray[0]} size="sm" mr="sm">
              {displayText}
            </Text>
          ))}
        <Select
          zIndex={500}
          size="xs"
          value={pageSize?.toString()}
          onChange={(value: string) => handleChangePageSize(+value)}
          itemComponent={SelectItem}
          data={pageSizeOptions}
        />
      </Flex>
    </Flex>
  );
};
