export function extractErrorCode(errorResponse: any) {
  if (errorResponse.data && errorResponse.data.Code !== undefined) {
    return errorResponse.data.Code;
  } else if (errorResponse?.data && errorResponse?.data?.Response) {
    const response = errorResponse.data.Response || {};
    const data = response.Data || {};
    return data.Code;
  }
  return errorResponse.data;
}
