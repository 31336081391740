import React, { FC } from 'react';
import { ActionIcon, Badge } from '@mantine/core';

import { IconX } from '@tabler/icons';
import { themeColors } from '@/theme/colors';

import { useStyles } from './styles';

interface IBadges {
  label: string;
  onRemove: () => void;
}

export const Badges: FC<IBadges> = ({ label, onRemove }) => {
  const { classes } = useStyles();

  return (
    <Badge
      className={classes.badge}
      variant="outline"
      color="gray"
      rightSection={
        <ActionIcon size={16} variant="transparent" onClick={onRemove}>
          <IconX size={16} color={themeColors.brandGray[0]} />
        </ActionIcon>
      }
    >
      {label}
    </Badge>
  );
};
