import React, { FC } from 'react';
import { Box, Text, Group } from '@mantine/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface ITagList {
  Name: string;
  Id: number;
  Count: number;
}

interface ITextFieldProps {
  title: string;
  tags?: ITagList[];
}

export const TextFieldTags: FC<ITextFieldProps> = ({ title, tags }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.container}>
      <Text className={classes.title}>{title}</Text>
      <Box className={classes.group}>
        <Text className={cx(classes.description)}>
          <Group spacing="xs">
            {Array.isArray(tags) && tags.length > 0
              ? tags.map((item, index) => (
                  <Text key={index} data-text={item.Name} className={classes.rounded}>
                    {item.Name}
                  </Text>
                ))
              : t('noInformation')}
          </Group>
        </Text>
      </Box>
    </Box>
  );
};
