import { useTranslation } from 'react-i18next';
export const deviceTypeToString = (deviceType: number): string => {
  const { t } = useTranslation();
  return deviceType === 0
    ? t('statistics.another')
    : deviceType === 1
    ? t('statistics.dekstop')
    : deviceType === 2
    ? t('statistics.mobile')
    : t('statistics.another');
};
