import { useContext } from 'react';
import { Box } from '@mantine/core';
import { useStyles } from './styles';
import { TextField } from '@components/fields/text-field';
import { UserInfoMainContext } from '../../panels';
import { TextListField } from '@components/fields/text-field/TextListField';
import { EmailActivationControl } from '@components/email-activation/emailActivationControl';
import { useTranslation } from 'react-i18next';

export const BaseInfo = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { userInfoCommon } = useContext(UserInfoMainContext);

  return (
    <Box className={classes.content}>
      <TextListField
        title={t('profile.position')}
        items={userInfoCommon?.Positions?.map((position) => position.Position.Name)}
      />
      <TextListField
        title={t('profile.role')}
        rounded={true}
        items={userInfoCommon?.Roles?.map((role) => role.Name)}
      />
      <TextField title={t('profile.unit')} description={userInfoCommon?.Customer?.Name} />
      <TextField title="Login" description={userInfoCommon?.Credentials?.Login} />
      <TextField
        title="Email"
        description={userInfoCommon?.Credentials?.Email}
        link
        leftSection={
          userInfoCommon?.Credentials?.Email &&
          !userInfoCommon?.Credentials?.IsConfirmed &&
          userInfoCommon?.Id &&
          userInfoCommon.Customer?.Id ? (
            <EmailActivationControl
              Email={userInfoCommon?.Credentials?.Email}
              Id={userInfoCommon?.Id}
              customerId={userInfoCommon.Customer?.Id}
            />
          ) : null
        }
      />

      <TextField title={t('profile.mobile')} description={userInfoCommon?.Common?.Phone} link />
      {/* <TextField
        title="Адрес в интернет"
        description={userInfoCommon?.AdditionalInformation?.InternetAddress}
        link
      /> */}
    </Box>
  );
};
