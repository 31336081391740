import { showNotification } from '@mantine/notifications';

interface IGetAllNotification {
  error: any;
  isSuccess: boolean;
  isError: boolean;
  successMessage: string;
  errorMessage?: string;
  errorTitle?: string;
}

export const universalNotification = (data: IGetAllNotification) => {
  const { error, isSuccess, isError, successMessage, errorTitle, errorMessage } = data;
  try {
    if (isSuccess) {
      showNotification({
        title: successMessage,
        message: '',
        autoClose: true,
        color: 'green',
        style: { zIndex: 1003 }
      });
    } else if (isError) {
      showNotification({
        title: errorTitle ?? `${error?.name} ${error?.ErrorCode}`,
        message: errorMessage ?? error?.ErrorMessage ?? '',
        autoClose: true,
        color: 'red',
        style: { zIndex: 1003 }
      });
    }
  } catch (e) {
    const error = e as Error;
    showNotification({
      title: `${error?.name}`,
      message: error?.message,
      autoClose: true,
      color: 'yellow',
      style: { zIndex: 1003 }
    });
  }
};
