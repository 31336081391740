import { useCallback, useEffect } from 'react';

import { universalNotification } from '@/lib/utils/notification';

import { useDeleteFilialsMutation } from '@/entities/admin-app/filials/api';

import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';
import { clearApiCacheByTags } from '@/entities/helpers';
import { rolesApi } from '@/entities/admin-app/roles/api';
import { useTranslation } from 'react-i18next';

export const useDeleteFilial = () => {
  const { t } = useTranslation();
  const { setFilialsTableFilters } = useActions();
  const { filters } = useAppSelector((state) => state.filials);

  const [deleteFilialMutation, deletionMutationResult] = useDeleteFilialsMutation();

  const deleteFilial = useCallback(
    async (id: number) => {
      try {
        const res = await deleteFilialMutation(id);
        if (!(res as any).error) {
          setFilialsTableFilters({
            ...filters,
            Offset: 0
          });
        }
      } catch (err: any) {
        console.warn(t('filials.errors.delete'), err);
      }
    },
    [filters]
  );

  useEffect(() => {
    const { isSuccess, isError, error } = deletionMutationResult;
    universalNotification({
      isSuccess: isSuccess,
      isError: isError,
      error: (error as any)?.data,
      successMessage: t('filials.successDelete')
    });
    if (isSuccess) {
      clearApiCacheByTags(rolesApi, ['Roles']);
    }
  }, [deletionMutationResult]);

  return { deleteFilial };
};
