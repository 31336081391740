import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Flex,
  LoadingOverlay,
  // Modal,
  Box,
  PasswordInput,
  Select,
  SelectItem,
  SimpleGrid,
  Text,
  TextInput,
  UnstyledButton
} from '@mantine/core';
import { useStyles } from '@/containers/pages/user/components/sections/linked-emails/styles';
import { ILinkedEmail, IServerDomains } from '@/entities/admin-app/email';
import { Modal } from '@/components/modal';
import { IconAt, IconChevronDown } from '@tabler/icons';
import {
  useEmailServerDomainsQuery,
  useLazyCreateEmailWithoutLinkedUserQuery
} from '@/entities/admin-app/email/api';
import { themeColors } from '@/theme/colors';
import { showNotification } from '@mantine/notifications';
import { FormErrors, useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { getValidateErrors /*, passwordReg*/ } from '@/lib/utils/validation';
import { z } from 'zod';
import { defaultMailServerPasswordOptions } from '@/mock-data/passwordOptions';
import { generatePasswordRegExp } from '@/containers/pages/user/components/sections/general-info/password/utils';
import { useGeneratePolicy } from '@hooks/password/useGeneratePolicy';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';

interface ICreateEmailAccountProps {
  handleAddEmail?: (data: ILinkedEmail) => void;
  opened: boolean;
  handleClose: () => void;
  filialId: number;
}
export const CreateEmailAccount = ({
  opened,
  handleClose,
  handleAddEmail,
  filialId
}: ICreateEmailAccountProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [servers, setServers] = useState<IServerDomains[]>([]);
  const [createEmail, { isFetching: createEmailFetching }] =
    useLazyCreateEmailWithoutLinkedUserQuery();
  const generatePasswordPolicyString = useGeneratePolicy();
  const passwordRegexp = generatePasswordRegExp({ options: defaultMailServerPasswordOptions });
  const { data, isLoading, isError, isSuccess } = useEmailServerDomainsQuery(filialId);

  const createAccountSchema = z.object({
    Email: z
      .string()
      .min(1, { message: t('emailMinError') })
      .refine((data) => !/\s/.test(data), {
        message: t('validation.emptyEmail')
      }),
    Password: z.string().regex(passwordRegexp, {
      message: generatePasswordPolicyString(defaultMailServerPasswordOptions)
    }),
    // .regex(passwordReg, {
    //   message: t('passwordRegError')
    // })

    Domain: z.string().min(1, { message: t('domainMinError') })
  });

  const createAccountForm = useForm({
    initialValues: {
      Email: '',
      Password: '',
      Domain: ''
    },
    validateInputOnChange: true,
    validate: (values: any): FormErrors => getValidateErrors(createAccountSchema, values)
  });

  useEffect(() => {
    if (data) {
      setServers(data);
    }
  }, [data]);

  const list = useMemo(
    () =>
      Array.isArray(servers)
        ? servers?.map(
            (Item: any, index: number) =>
              ({
                key: index,
                label: String(Item?.Domain),
                value: `${Item?.ServerId}_${Item.Domain}`
              } as SelectItem)
          )
        : [],
    [servers]
  );

  const modalClose = () => {
    createAccountForm.reset();
    handleClose();
  };

  const handleCreate = async () => {
    const { Email, Domain, Password } = createAccountForm.values;

    try {
      const selectedServer = Domain.split('_')[0];
      const selectedDomain = Domain.split('_')[1];
      const res = await createEmail({
        Email: `${Email.toLowerCase()}@${selectedDomain}`,
        Password: Password,
        EmailServer: {
          Id: Number(selectedServer)
        }
      });

      if ('error' in res) {
        const codeError = extractErrorCode(res.error);
        showNotification({
          title: generateErrorMessage(codeError),
          message: '',
          autoClose: true,
          color: 'red',
          style: { zIndex: 1003 }
        });
      } else {
        if (handleAddEmail && res.data) {
          handleAddEmail({
            Id: res.data.Id,
            Password: Password,
            UserId: res.data.UserId,
            Email: res.data.Email,
            EmailServer: { Id: res?.data?.EmailServer?.Id || 0, Name: res?.data?.EmailServer?.Name }
          });
        }

        modalClose();
      }
    } catch (err: any) {
      console.warn('Ошибка смены пароля', err);
    }
  };

  const handleChange = (event: any) => {
    const { value } = event.currentTarget;
    if (!value.includes('@')) {
      createAccountForm.setFieldValue('Email', value);
    }
  };

  return (
    <Modal
      size="450px"
      opened={opened}
      onClose={modalClose}
      title={t('profile.createAccount')}
      zIndex={1300}
    >
      <Box className={classes.modalRoot}>
        {list?.length && isSuccess ? (
          <>
            <Box className={classes.modalContainer}>
              <Flex w="100%">
                <TextInput
                  w="100%"
                  className={classes.textFieldInput}
                  placeholder={t('profile.email')}
                  withAsterisk
                  {...createAccountForm.getInputProps('Email')}
                  onChange={handleChange}
                />
                <Flex mr="sm" ml="sm" mt="8px">
                  <IconAt size={22} color={themeColors.brandGray[0]} />
                </Flex>
                <Flex className={classes.createContainer}>
                  <Select
                    data={list}
                    w="100%"
                    rightSectionWidth={30}
                    rightSection={<IconChevronDown color="#98A2B3" />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    maxDropdownHeight={150}
                    {...createAccountForm.getInputProps('Domain')}
                  />
                </Flex>
              </Flex>
              <SimpleGrid cols={1} mt={16} w="100%">
                <PasswordInput
                  data-lpignore="true"
                  autoComplete="new-password"
                  className={classes.textFieldInput}
                  placeholder={t('profile.password')}
                  description={t('profile.passwordRequiring')}
                  label={t('profile.password')}
                  withAsterisk
                  {...createAccountForm.getInputProps('Password')}
                />
              </SimpleGrid>
            </Box>
            <Box className={classes.modalFooter}>
              <Button
                size="sm"
                radius={8}
                w="49%"
                disabled={!createAccountForm.isValid() || createEmailFetching}
                onClick={() => handleCreate()}
              >
                {t('profile.add')}
              </Button>
              <UnstyledButton
                className={classes.modalCancelBtn}
                w="49%"
                onClick={() => modalClose()}
              >
                {t('profile.cancel')}
              </UnstyledButton>
            </Box>
          </>
        ) : (
          <Box className={classes.modalContainer}>
            <Text>{t('profile.createMailAccountRequiring')}</Text>
          </Box>
        )}
        <LoadingOverlay visible={(isLoading && !isError) || createEmailFetching} overlayBlur={2} />
      </Box>
    </Modal>
  );
};
